<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import BottleneckModal from './BottleneckModal.vue';
import BottleneckForm, {
    type BottleneckFormValues,
} from './BottleneckForm.vue';
import { useMutation } from '@vue/apollo-composable';
import { graphql } from '@/gql';
import { useNotificationStore } from '@/stores/notification';
import type { NonNullableFields } from '@/utils';
import { useDateTime } from '@/composables/datetime';

const showDialog = defineModel<boolean>({ default: false });
const props = defineProps<{ deliverableId: string }>();

const { t } = useI18n({
    messages: {
        nl: {
            title: 'Bottleneck aanmaken',
            create_success: 'Bottleneck succesvol aangemaakt',
        },
        en: {
            title: 'Create bottleneck',
            create_success: 'Bottleneck successfully created',
        },
    },
});

const notificationStore = useNotificationStore();

const { mutate: createBottleneck, loading: isCreating } = useMutation(
    graphql(`
        mutation CreateBottleneck($input: CreateBottleneckInput!) {
            createBottleneck(input: $input) {
                bottleneck {
                    id
                    uuid
                }
            }
        }
    `),
    {
        refetchQueries: [
            'Deliverable',
            'ProjectPhases',
            'DeliverableBottlenecks',
        ],
        awaitRefetchQueries: true,
    }
);

async function handleCreate({
    resolutionDate,
    ...values
}: NonNullableFields<BottleneckFormValues>) {
    try {
        const { formattedDate } = useDateTime(resolutionDate);
        if (!formattedDate) {
            throw new Error('Invalid date');
        }
        await createBottleneck({
            input: {
                deliverableId: props.deliverableId,
                resolutionDate: formattedDate.value,
                ...values,
            },
        });

        showDialog.value = false;
        notificationStore.add({
            type: 'success',
            message: t('create_success'),
        });
    } catch {
        notificationStore.add({ type: 'error', message: t('create_error') });
    }
}
</script>

<template>
    <BottleneckModal v-model="showDialog" :title="t('title')">
        <template #activator="{ props: activatorProps }">
            <slot name="activator" :props="activatorProps" />
        </template>
        <BottleneckForm
            :disabled="isCreating"
            :loading="isCreating"
            @cancel="showDialog = false"
            @submit="handleCreate"
        />
    </BottleneckModal>
</template>
