import { useUserSettingsStore } from '@/stores/user-settings';
import { useUserStore } from '@/stores/user';
import { usePolicyStore } from '@/stores/policy';
import { PolicyAction, UserRoles } from '@/types';
import type { UUID, AccessRole } from '@/types';
import { computed } from 'vue';

export function usePermissions() {
    const userSettingsStore = useUserSettingsStore();
    const userStore = useUserStore();
    const policyStore = usePolicyStore();

    function hasAccessRole(accessRole: AccessRole['name']) {
        return userStore.accessRoles.some(
            (role) =>
                role.name === accessRole &&
                role.portfolioId === userSettingsStore.activePortfolio?.id &&
                role.accountId === userSettingsStore.activeOrganisation?.id
        );
    }

    const isJigsEye = computed(() =>
        userStore.accessRoles.some((role) => role.name === UserRoles.JigsEye)
    );

    const isAdmin = computed(() => hasAccessRole(UserRoles.Admin));

    const isProgramManager = computed(() =>
        hasAccessRole(UserRoles.ProgramManager)
    );

    const isProjectManager = computed(() =>
        hasAccessRole(UserRoles.ProjectManager)
    );

    const isUser = computed(() => hasAccessRole(UserRoles.User));

    function hasPermission(
        action: PolicyAction,
        resource: UUID = '*',
        portfolio?: UUID,
        account?: UUID
    ) {
        return policyStore.hasPolicyAction(action, {
            account: account ?? userSettingsStore.activeOrganisation?.id,
            portfolio: portfolio ?? userSettingsStore.activePortfolio?.id,
            resource,
        });
    }

    return {
        hasPermission,
        isJigsEye,
        isAdmin,
        isProjectManager,
        isProgramManager,
        isUser,
    };
}
