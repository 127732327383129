<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import { useMutation } from '@vue/apollo-composable';
import { useNotificationStore } from '@/stores/notification';
import { graphql } from '@/gql';
import { watchEffect } from 'vue';
import type { UpdateDeliverableStatusInput } from '@/gql/graphql';
import { DeliverableStatusType } from '@/gql/graphql';
import confetti from 'canvas-confetti';

const props = defineProps<{
    deliverableId: string;
    refetchQueries: string[];
}>();
const emit = defineEmits<{ success: []; loading: [value: boolean] }>();

const { t } = useI18n({
    messages: {
        nl: {
            title: '@:deliverable afronden',
            dont_finish: 'Niet afronden',
            finish_text:
                'Alle bottlenecks en escalaties zijn opgelost. Wil je deze @.lower:deliverable afronden? ',
            finish: 'Afronden',

            finish_success: '@:deliverable is afgerond',
            finish_error:
                'Er is een fout opgetreden bij het afronden van het @.lower:deliverable',
        },
        en: {
            title: 'Finish @.lower:deliverable',
            dont_finish: "Don't finish",
            finish_text:
                'All bottlenecks and escalations have been resolved. Do you want to finish this @.lower:deliverable?',
            finish: 'Finish',
            finish_success: '@:deliverable has been finished',
            finish_error:
                'An error occurred while finishing the @.lower:deliverable',
        },
    },
});

const notificationStore = useNotificationStore();

const { mutate: finishDeliverable, loading: finishDeliverableLoading } =
    useMutation(
        graphql(`
            mutation UpdateDeliverableStatus(
                $input: UpdateDeliverableStatusInput!
            ) {
                updateDeliverableStatus(input: $input) {
                    ok
                }
            }
        `),
        {
            refetchQueries: props.refetchQueries,
            awaitRefetchQueries: true,
        }
    );

watchEffect(() => {
    emit('loading', finishDeliverableLoading.value);
});

async function handleStatusUpdate(
    deliverableStatus: UpdateDeliverableStatusInput['status']
) {
    try {
        await finishDeliverable({
            input: {
                deliverableId: props.deliverableId,
                status: deliverableStatus,
            },
        });

        emit('success');

        if (deliverableStatus === DeliverableStatusType.FINISHED) {
            confetti();
            notificationStore.add({
                type: 'success',
                message: t('finish_success'),
            });
        }
    } catch {
        notificationStore.add({ type: 'error', message: t('finish_error') });
    }
}

/**
    When the last bottleneck is solved, the server automaticaly sets the deliverable status to "finished".
    Therefore instead of 'confirming' the finish, we need to 'cancel' the finish on the front-end.
*/

function handleCancel() {
    // Set deliverable status to "pending"
    handleStatusUpdate(DeliverableStatusType.PENDING);
}

async function handleFinish() {
    // Set deliverable status to "finished"
    handleStatusUpdate(DeliverableStatusType.FINISHED);
}
</script>

<template>
    <ConfirmationDialog
        :title="t('title')"
        :confirm-button-text="t('finish')"
        :cancel-button-text="t('dont_finish')"
        persistent
        @confirm="handleFinish"
        @cancel="handleCancel"
    >
        <span class="text-body-small">{{ t('finish_text') }}</span>
    </ConfirmationDialog>
</template>
