<script setup lang="ts">
import { useField, useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import {
    VForm,
    VTextField,
    VRow,
    VCol,
    VBtn,
    VSpacer,
} from 'vuetify/components';
import { add } from 'date-fns';
import { VDateInput } from 'vuetify/labs/VDateInput';
import { object, string, date } from 'zod';
import { requirements } from '@/utils/formRequirements';
import { toTypedSchema } from '@vee-validate/zod';
import { useDateTime } from '@/composables/datetime';

// NOTE: hidden description
// The description field is being hidden but still necessary for the mutation
// The back-end work is not possible at time of writing (13 aug 2024)

export interface PhaseFormValues {
    name: string;
    description?: string;
    startDate: string;
    endDate: string;
}

const { t } = useI18n();

const props = defineProps<{
    initialValues?: PhaseFormValues | null;
}>();

const emit = defineEmits<{
    submit: [values: PhaseFormValues];
    cancel: [];
}>();

const { phase: pr } = requirements;
const validationSchema = object({
    name: string()
        .min(pr.name.min, t('error.required_field'))
        .max(pr.name.max, t('error.maximum_characters', { max: pr.name.max })),
    description: string().nullable(),
    startDate: date(),
    endDate: date(),
});

const { handleSubmit, meta } = useForm({
    validationSchema: toTypedSchema(validationSchema),
    initialValues: {
        name: props.initialValues?.name ?? '',
        description: props.initialValues?.description ?? '',
        startDate: props.initialValues?.startDate
            ? new Date(props.initialValues.startDate)
            : null ?? new Date(),
        endDate: props.initialValues?.endDate
            ? new Date(props.initialValues.endDate)
            : null ?? add(new Date(), { months: 1 }),
    },
});

const name = useField<string>('name');
const startDate = useField<string>('startDate');
const endDate = useField<string>('endDate');

const submit = handleSubmit((values) => {
    const { formattedDate: startDate } = useDateTime(values.startDate);
    const { formattedDate: endDate } = useDateTime(values.endDate);

    if (!startDate.value || !endDate.value) return;

    emit('submit', {
        name: values.name,
        description: values.description ?? ' ',
        startDate: startDate.value,
        endDate: endDate.value,
    });
});
</script>

<template>
    <v-form class="d-flex flex-column ga-3" @submit.prevent="submit">
        <v-text-field
            v-model="name.value.value"
            :label="t('name')"
            :error-messages="name.errorMessage.value"
            :clearable="false"
            autofocus
        ></v-text-field>
        <v-row>
            <v-col>
                <v-date-input
                    v-model="startDate.value.value"
                    :label="t('start_date')"
                    :error-messages="startDate.errorMessage.value"
                    variant="outlined"
                    prepend-icon=""
                    prepend-inner-icon="$date"
                    placeholder="dd-mm-yyyy"
                ></v-date-input>
            </v-col>
            <v-col>
                <v-date-input
                    v-model="endDate.value.value"
                    :label="t('end_date')"
                    :error-messages="endDate.errorMessage.value"
                    variant="outlined"
                    prepend-icon=""
                    prepend-inner-icon="$date"
                    placeholder="dd-mm-yyyy"
                ></v-date-input>
            </v-col>
        </v-row>

        <div class="d-flex justify-end ga-3 mt-5">
            <v-spacer> </v-spacer>
            <v-btn variant="outlined" @click="emit('cancel')">{{
                t('cancel')
            }}</v-btn>
            <v-btn type="submit" :disabled="!meta.valid || !meta.dirty">{{
                t('save')
            }}</v-btn>
        </div>
    </v-form>
</template>
