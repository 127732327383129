<script lang="ts" setup>
import { graphql } from '@/gql';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';
import { useRouteParams } from '@vueuse/router';
import {
    VContainer,
    VRow,
    VCol,
    VBtn,
    VCard,
    VSpacer,
    VIcon,
    VList,
    VListItem,
    VListItemTitle,
    VListItemSubtitle,
    VProgressLinear,
} from 'vuetify/components';
import { useI18n } from 'vue-i18n';
import { PolicyAction } from '@/types';
import { usePermissions } from '@/composables/permissions';
import ProjectDocumentCreateModal from '@/components/ProjectDocumentCreateModal.vue';
import DocumentDeleteDialog from './DocumentDeleteDialog.vue';

const { hasPermission } = usePermissions();

const { t } = useI18n({
    inheritLocale: true,
    messages: {
        nl: {
            create: '@:document aanmaken',
            error: 'Er ging iets mis bij het laden van de @.lower:documents',
            no_data: 'Geen @.lower:documents beschikbaar',
        },
        en: {
            create: 'Create @.lower:document',
            error: 'Failed to load @.lower:documents',
            no_data: 'No @.lower:documents available',
        },
    },
});

const id = useRouteParams('id');

const variables = computed(() => ({
    filters: [
        {
            field: 'id',
            value: id.value,
        },
    ],
}));

const { result, loading, error } = useQuery(
    graphql(`
        query ProjectDocuments($filters: [FilterCriterion]) {
            projects(filters: $filters) {
                items {
                    id
                    masterPortfolioId
                    abbreviation
                    description
                    goal
                    name
                    result
                    documents {
                        id
                        name
                        description
                        url
                    }
                }
            }
        }
    `),
    variables,
    {
        fetchPolicy: 'cache-first',
    }
);

const project = computed(() => result.value?.projects?.items[0] || {});
const documents = computed(() => project.value.documents || []);
const totalCount = computed(() => documents.value.length);
</script>

<template>
    <v-container class="pt-6">
        <v-row>
            <v-col md="8" class="d-flex align-center">
                <h2 class="text-primary-40 text-title-large">
                    <VIcon
                        icon="$file"
                        color="primary-20"
                        size="1.75rem"
                        class="mr-1"
                    />
                    {{ t('documents') }}
                </h2>
            </v-col>
            <v-col md="4" class="d-flex justify-end">
                <v-spacer />
                <ProjectDocumentCreateModal
                    v-if="
                        hasPermission(
                            PolicyAction.PROJECT_WRITE,
                            project?.id,
                            project?.masterPortfolio?.id
                        )
                    "
                    :project
                >
                    <template #activator="{ props }">
                        <!-- Button defaults based on src/plugins/vuetify/defaults.ts:AppListTable.AppPageTitle.VBtn -->
                        <v-btn
                            prepend-icon="$add"
                            :disabled="loading"
                            v-bind="props"
                            variant="outlined"
                        >
                            {{ t('create') }}
                        </v-btn>
                    </template>
                </ProjectDocumentCreateModal>
            </v-col>
        </v-row>
    </v-container>
    <v-container>
        <v-row>
            <v-col md="12">
                <v-card>
                    <v-list
                        v-if="!loading"
                        lines="two"
                        bg-color="transparent"
                        class="py-0"
                    >
                        <v-list-item
                            v-for="(document, index) in documents"
                            :key="document.id"
                            :class="{ 'border-t-thin': index > 0 }"
                        >
                            <v-list-item-title
                                color="primary-95"
                                class="text-primary font-weight-bold"
                            >
                                {{ document.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ document.description }}
                            </v-list-item-subtitle>
                            <template #append>
                                <a
                                    :href="
                                        document.url.replace(
                                            's3:4569',
                                            'localhost:9018'
                                        )
                                    "
                                    target="_blank"
                                    class="text-primary"
                                    :download="document.name"
                                >
                                    <v-icon>cloud_download</v-icon>
                                </a>
                                <DocumentDeleteDialog
                                    v-if="
                                        hasPermission(
                                            PolicyAction.PROJECT_WRITE,
                                            project?.id,
                                            project?.masterPortfolio?.id
                                        )
                                    "
                                    :document-id="document.id"
                                >
                                    <template #activator="{ props }">
                                        <!-- Button defaults based on src/plugins/vuetify/defaults.ts:AppListTable.AppPageTitle.VBtn -->
                                        <v-btn
                                            prepend-icon="$add"
                                            :disabled="loading"
                                            icon="delete"
                                            v-bind="props"
                                            variant="text"
                                            class="ml-3"
                                        >
                                        </v-btn>
                                    </template>
                                </DocumentDeleteDialog>
                            </template>
                        </v-list-item>
                    </v-list>
                    <p v-if="!loading && totalCount === 0">
                        {{ t('no_data') }}
                    </p>
                    <p v-if="!loading && error">
                        {{ t('error') }}
                    </p>
                    <v-progress-linear v-if="loading" indeterminate />
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
