<script setup lang="ts">
import { BottleneckStatus } from '@/gql/graphql';
import { useI18n } from 'vue-i18n';

defineProps<{ status: BottleneckStatus }>();

const { t } = useI18n({
    messages: {
        nl: {
            bottleneck: 'Bottleneck',
            solved: 'Opgelost',
            escalation: 'Escalatie',
        },
        en: {
            bottleneck: 'Bottleneck',
            solved: 'Solved',
            escalation: 'Escalation',
        },
    },
});
</script>

<template>
    <span>{{ t(status) }}</span>
</template>
