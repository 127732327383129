<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import BottleneckModal from './BottleneckModal.vue';
import BottleneckEscalateForm, {
    type BottleneckEscalateFormValues,
} from './BottleneckEscalateForm.vue';
import { useUserSettingsStore } from '@/stores/user-settings';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { graphql } from '@/gql';
import { useNotificationStore } from '@/stores/notification';
import { computed } from 'vue';
import type { User, Role, UpdateBottleneckStatusInput } from '@/gql/graphql';
import type { UpdateBottleneckInput } from '@/gql/graphql';
import { BottleneckStatusType } from '@/gql/graphql';

const { activePortfolio } = useUserSettingsStore();

const showDialog = defineModel<boolean>({ default: false });

const props = defineProps<{
    bottleneck: {
        id: string;
        name: string;
        description: string;
        responsible: string;
        solution: string | null;
        resolutionDate: string | null;
    };
}>();

const { t } = useI18n({
    messages: {
        nl: {
            title: 'Escalatie',
            update_success: 'Bottleneck succesvol bewerkt',
        },
        en: {
            title: 'Escalate',
            update_success: 'Bottleneck successfully edited',
        },
    },
});

const { result: portfolioUsersResult } = useQuery(
    graphql(`
        query PortfolioloUsers($portfolioId: ID!) {
            users(portfolioId: $portfolioId) {
                items {
                    id
                    fullName
                    access {
                        roles {
                            name
                        }
                    }
                }
            }
        }
    `),
    {
        portfolioId: activePortfolio?.id || '',
    }
);

const escalationListUsers = computed<Pick<User, 'fullName' | 'id'>[]>(() => {
    const users = portfolioUsersResult.value?.users.items as User[];

    if (!users) return [];

    return users
        .filter((user) =>
            user?.access?.roles?.some(
                (role: Role) => role.name === 'Escalation list'
            )
        )
        .map((user) => ({ fullName: user.fullName, id: user.id }));
});

const notificationStore = useNotificationStore();

const { mutate: escalateBottleneck, loading: isEscalating } = useMutation(
    graphql(`
        mutation EscalateBottleneck(
            $bottleneck: UpdateBottleneckInput!
            $status: UpdateBottleneckStatusInput!
        ) {
            updateBottleneck(input: $bottleneck) {
                result
            }
            updateBottleneckStatus(input: $status) {
                ok
            }
        }
    `),
    {
        refetchQueries: [
            'Deliverable',
            'ProjectPhases',
            'DeliverableBottlenecks',
        ],
        awaitRefetchQueries: true,
    }
);

async function handleUpdate(values: BottleneckEscalateFormValues) {
    const bottleneck: UpdateBottleneckInput = {
        bottleneckId: props.bottleneck.id,
        escalationReason: values.escalationReason,
        name: props.bottleneck.name,
        responsible: props.bottleneck.responsible,
        solution: values.solution,
        description: props.bottleneck.description,
        resolutionDate:
            props.bottleneck.resolutionDate || new Date().toISOString(),
    };
    const status: UpdateBottleneckStatusInput = {
        bottleneckId: props.bottleneck.id,
        status: BottleneckStatusType.ESCALATION,
        assignUsers: [values.assignedUser],
        sendNotification: values.sendNotification,
    };

    try {
        await escalateBottleneck({
            bottleneck,
            status,
        });

        showDialog.value = false;
        notificationStore.add({
            type: 'success',
            message: t('update_success'),
        });
    } catch {
        notificationStore.add({ type: 'error', message: t('update_error') });
    }
}
</script>

<template>
    <BottleneckModal v-model="showDialog" :title="t('title')">
        <template #activator="{ props: activatorProps }">
            <slot name="activator" :props="activatorProps" />
        </template>
        <BottleneckEscalateForm
            :loading="isEscalating"
            :name="bottleneck.name"
            :escalation-list="escalationListUsers"
            :initial-values="{
                solution: bottleneck.solution ?? '',
                assignedUser: '',
                escalationReason: '',
                sendNotification: false,
            }"
            @submit="handleUpdate"
            @cancel="showDialog = false"
        />
    </BottleneckModal>
</template>
