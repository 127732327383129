<script setup lang="ts">
import { useField, useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import {
    VForm,
    VTextField,
    VTextarea,
    VRow,
    VCol,
    VBtn,
} from 'vuetify/components';
import { toTypedSchema } from '@vee-validate/zod';
import { requirements } from '@/utils/formRequirements';
import { string, object } from 'zod';
import type { CreateHighlightInput } from '@/gql/graphql';

export type HighlightFormValues = Pick<
    CreateHighlightInput,
    'name' | 'description'
>;

const props = defineProps<{
    initialValues?: (HighlightFormValues & { id?: string }) | null;
    disabled?: boolean;
    loading?: boolean;
}>();

const emit = defineEmits<{
    submit: [values: HighlightFormValues];
    cancel: [];
}>();

const { t } = useI18n();

const { highlight } = requirements;
const validationSchema = object({
    name: string({
        invalid_type_error: t('error.required_field'),
    })
        .min(highlight.name.min, t('error.required_field'))
        .max(
            highlight.name.max,
            t('error.maximum_characters', { max: highlight.name.max })
        ),
    description: string({
        invalid_type_error: t('error.required_field'),
    })
        .min(highlight.description.min, t('error.required_field'))
        .max(
            highlight.description.max,
            t('error.maximum_characters', { max: highlight.description.max })
        ),
});

const { handleSubmit, meta } = useForm({
    validationSchema: toTypedSchema(validationSchema),
    initialValues: props.initialValues
        ? props.initialValues
        : {
              name: '',
              description: '',
          },
});

const name = useField<string | null>('name');
const description = useField<string | null>('description');

const submit = handleSubmit((values) => {
    emit('submit', values);
});
</script>

<template>
    <v-form class="d-flex flex-column ga-3" :disabled @submit.prevent="submit">
        <v-row>
            <v-col cols="12">
                <v-text-field
                    v-model="name.value.value"
                    :placeholder="t('name')"
                    :error-messages="name.errorMessage.value"
                    :counter="highlight.name.max"
                    :maxlength="highlight.name.max"
                    :label="t('name')"
                    name="name"
                    autofocus
                ></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-textarea
                    v-model="description.value.value"
                    name="description"
                    :label="t('description')"
                    :placeholder="t('description')"
                    :counter="highlight.description.max"
                    :maxlength="highlight.description.max"
                    :error-messages="description.errorMessage.value"
                    :clearable="false"
                    persistent-placeholder
                ></v-textarea>
            </v-col>
            <v-col cols="12">
                <div class="d-flex justify-end ga-3">
                    <v-btn variant="text" @click="$emit('cancel')">
                        {{ t('cancel') }}
                    </v-btn>
                    <v-btn
                        type="submit"
                        variant="elevated"
                        :disabled="!meta.valid || !meta.dirty"
                        :loading
                    >
                        {{ t('save') }}
                    </v-btn>
                </div>
            </v-col>
        </v-row>
    </v-form>
</template>
