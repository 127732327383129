export enum RoleName {
    JIGSEYE = 'JigsEye',
    ADMIN = 'Admin',
    ORGANIZATION_ADMIN = 'Organization admin',
    BOARD = 'Board',
    PORTFOLIO_MANAGER = 'Portfolio manager',
    PROGRAM_MANAGER = 'Program manager',
    PROJECT_MANAGER = 'Project manager',
    TEAM = 'Team',
    USER = 'User',
    ESCALATION_LIST = 'Escalation list',
}
