<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import HighlightModal from './HighlightModal.vue';
import HighlightForm, { type HighlightFormValues } from './HighlightForm.vue';
import { useMutation } from '@vue/apollo-composable';
import { graphql } from '@/gql';
import { useNotificationStore } from '@/stores/notification';

const showDialog = defineModel<boolean>({ default: false });
const { projectId } = defineProps<{ projectId: string }>();

const { t } = useI18n({
    messages: {
        nl: {
            title: '@:highlight aanmaken',
            create_success: '@:highlight succesvol aangemaakt',
        },
        en: {
            title: 'Create @.lower:highlight',
            create_success: '@:highlight successfully created',
        },
    },
});

const notificationStore = useNotificationStore();

const { mutate: createHighlight, loading: isCreating } = useMutation(
    graphql(`
        mutation CreateHighlight($input: CreateHighlightInput!) {
            createHighlight(input: $input) {
                highlight {
                    id
                }
            }
        }
    `),
    {
        refetchQueries: ['ProjectHighlights'],
        awaitRefetchQueries: true,
    }
);

async function handleCreate(values: HighlightFormValues) {
    try {
        await createHighlight({
            input: { projectId, ...values },
        });

        showDialog.value = false;
        notificationStore.add({
            type: 'success',
            message: t('create_success'),
        });
    } catch {
        notificationStore.add({ type: 'error', message: t('create_error') });
    }
}
</script>

<template>
    <HighlightModal v-model="showDialog" :title="t('title')">
        <template #activator="{ props: activatorProps }">
            <slot name="activator" :props="activatorProps" />
        </template>
        <HighlightForm
            :disabled="isCreating"
            :loading="isCreating"
            @cancel="showDialog = false"
            @submit="handleCreate"
        />
    </HighlightModal>
</template>
