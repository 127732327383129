<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import PreviewModal from './PreviewModal.vue';
import PreviewForm, { type PreviewFormValues } from './PreviewForm.vue';
import { useMutation } from '@vue/apollo-composable';
import { graphql } from '@/gql';
import { useNotificationStore } from '@/stores/notification';

const showDialog = defineModel<boolean>({ default: false });
const { preview } = defineProps<{
    preview: PreviewFormValues & { id: string };
}>();

const { t } = useI18n({
    messages: {
        nl: {
            title: '@:preview bewerken',
            update_success: '@:preview succesvol bewerkt',
        },
        en: {
            title: 'Edit @.lower:preview',
            update_success: '@:preview successfully edited',
        },
    },
});

const notificationStore = useNotificationStore();

const { mutate: updatePreview, loading: isUpdating } = useMutation(
    graphql(`
        mutation UpdatePreview($input: UpdatePreviewInput!) {
            updatePreview(input: $input) {
                ok
            }
        }
    `),
    {
        refetchQueries: ['ProjectPreviews'],
        awaitRefetchQueries: true,
    }
);

async function handleUpdate(values: PreviewFormValues) {
    try {
        await updatePreview({
            input: { previewId: preview.id, ...values },
        });

        showDialog.value = false;
        notificationStore.add({
            type: 'success',
            message: t('update_success'),
        });
    } catch {
        notificationStore.add({ type: 'error', message: t('update_error') });
    }
}
</script>

<template>
    <PreviewModal v-model="showDialog" :title="t('title')">
        <template #activator="{ props: activatorProps }">
            <slot name="activator" :props="activatorProps" />
        </template>
        <PreviewForm
            :disabled="isUpdating"
            :loading="isUpdating"
            :initial-values="preview"
            @cancel="showDialog = false"
            @submit="handleUpdate"
        />
    </PreviewModal>
</template>
