<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import { VTextField } from 'vuetify/components';
import { useMutation } from '@vue/apollo-composable';
import { useNotificationStore } from '@/stores/notification';
import { useField } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/zod';
import { graphql } from '@/gql';
import { string } from 'zod';

const props = defineProps<{ projectId: string }>();

const emit = defineEmits<{ (e: 'success'): void; (e: 'error'): void }>();
const { t } = useI18n({
    inheritLocale: true,
    messages: {
        nl: {
            delete: 'Verwijderen',
            delete_title: 'Permanent verwijderen',
            delete_confirmation:
                'Weet je zeker dat je dit @.lower:project wilt verwijderen?',
            delete_success: '@:project succesvol verwijderd',
            delete_reason: 'Reden voor verwijdering',
        },
        en: {
            delete: 'Delete',
            delete_title: 'Permanently delete',
            delete_confirmation:
                "Are you sure you want to delete this @.lower:{'project'}?",
            delete_success: '@:project successfully deleted',
            delete_reason: 'Reason for deletion',
        },
    },
});

const notificationStore = useNotificationStore();

const reason = useField(
    'reason',
    toTypedSchema(string().min(3, t('error.minimum_characters', { count: 3 })))
);

function handleClearReason() {
    reason.value.value = '';
}

function handleUpdate(showDialog: boolean) {
    if (showDialog) {
        reason.resetField();
    }
}

const { mutate: deleteProject, loading: deleteProjectLoading } = useMutation(
    graphql(`
        mutation DeleteProject($input: DeleteProjectInput!) {
            deleteProject(input: $input) {
                ok
            }
        }
    `),
    {
        refetchQueries: ['Projects', 'ProjectSubProjects'],
        awaitRefetchQueries: true,
    }
);

async function handleDelete() {
    try {
        await deleteProject({
            input: {
                projectId: props.projectId,
                deleteReason: reason.value.value,
            },
        });

        emit('success');

        notificationStore.add({
            type: 'success',
            message: t('delete_success'),
        });
    } catch {
        notificationStore.add({ type: 'error', message: t('delete_error') });
    }
}
</script>

<template>
    <ConfirmationDialog
        :title="t('delete_title')"
        :text="t('delete_confirmation')"
        :confirm-button-text="t('delete')"
        :confirm-button-disabled="!reason.meta.valid"
        :confirm-required="false"
        persistent
        @confirm="handleDelete"
        @update:model-value="handleUpdate"
    >
        <template #activator="{ props: activatorProps }">
            <slot
                name="activator"
                :props="activatorProps"
                :loading="deleteProjectLoading"
            />
        </template>
        <v-text-field
            v-model="reason.value.value"
            class="mt-8"
            :label="t('delete_reason')"
            :error-messages="reason.errorMessage.value"
            @click:clear="handleClearReason"
        />
    </ConfirmationDialog>
</template>
