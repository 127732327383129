<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import BottleneckModal from './BottleneckModal.vue';
import EscalationCreateForm, {
    type EscalationCreateFormValues,
} from './EscalationCreateForm.vue';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { useUserSettingsStore } from '@/stores/user-settings';
import { useNotificationStore } from '@/stores/notification';
import { graphql } from '@/gql';
import type { Role, UpdateBottleneckInput, User } from '@/gql/graphql';
import { computed } from 'vue';
import type { CreateBottleneckInput } from '@/gql/graphql';
import type { UpdateBottleneckStatusInput } from '@/gql/graphql';
import { BottleneckStatusType } from '@/gql/graphql';
import { useDateTime } from '@/composables/datetime';

const { t } = useI18n({
    messages: {
        nl: {
            title: 'Escalatie aanmaken',
            create_success: 'Escalatie succesvol aangemaakt',
        },
        en: {
            title: 'Create escalation',
            create_success: 'Escalation successfully created',
        },
    },
});

const notificationStore = useNotificationStore();
const showDialog = defineModel<boolean>({ default: false });
const props = defineProps<{ deliverableId: string }>();

// Retrieve escalation list
const { activePortfolio } = useUserSettingsStore();

const { result: portfolioUsersResult } = useQuery(
    graphql(`
        query PortfolioloUsers($portfolioId: ID!) {
            users(portfolioId: $portfolioId) {
                items {
                    id
                    fullName
                    access {
                        roles {
                            name
                        }
                    }
                }
            }
        }
    `),
    {
        portfolioId: activePortfolio?.id || '',
    }
);

const escalationListUsers = computed<Pick<User, 'fullName' | 'id'>[]>(() => {
    const users = portfolioUsersResult.value?.users.items as User[];

    if (!users) return [];

    return users
        .filter((user) =>
            user?.access?.roles?.some(
                (role: Role) => role.name === 'Escalation list'
            )
        )
        .map((user) => ({ fullName: user.fullName, id: user.id }));
});

const { mutate: createBottleneck, loading: isCreating } = useMutation(
    graphql(`
        mutation CreateBottleneck($input: CreateBottleneckInput!) {
            createBottleneck(input: $input) {
                bottleneck {
                    id
                    uuid
                }
            }
        }
    `)
);

const { mutate: escalateBottleneck, loading: isEscalating } = useMutation(
    graphql(`
        mutation EscalateBottleneck(
            $bottleneck: UpdateBottleneckInput!
            $status: UpdateBottleneckStatusInput!
        ) {
            updateBottleneck(input: $bottleneck) {
                result
            }
            updateBottleneckStatus(input: $status) {
                ok
            }
        }
    `),
    {
        refetchQueries: [
            'Deliverable',
            'ProjectPhases',
            'DeliverableBottlenecks',
            'MetroProjects',
        ],
        awaitRefetchQueries: true,
    }
);

async function handleCreate(values: EscalationCreateFormValues) {
    try {
        const { formattedDate } = useDateTime(values.resolutionDate);

        if (!formattedDate.value) {
            throw new Error('Invalid date');
        }

        // Create bottleneck
        const response = await createBottleneck({
            input: {
                name: values.name,
                description: values.description,
                responsible: values.responsible,
                solution: values.solution,
                resolutionDate: formattedDate.value,
                deliverableId: props.deliverableId,
            } satisfies CreateBottleneckInput,
        });
        const uuid = response?.data?.createBottleneck?.bottleneck?.uuid;

        if (!uuid) {
            throw new Error('Unable to create bottleneck');
        }

        await escalateBottleneck({
            bottleneck: {
                name: values.name,
                description: values.description,
                responsible: values.responsible,
                solution: values.solution,
                resolutionDate: formattedDate.value,
                bottleneckId: uuid,
            } satisfies UpdateBottleneckInput,

            status: {
                bottleneckId: uuid,
                status: BottleneckStatusType.ESCALATION,
                assignUsers: [values.assignedUser],
                sendNotification: values.sendNotification,
            } satisfies UpdateBottleneckStatusInput,
        });

        notificationStore.add({
            type: 'success',
            message: t('create_success'),
        });

        showDialog.value = false;
    } catch (e) {
        notificationStore.add({ type: 'error', message: t('create_error') });
    }
}
</script>

<template>
    <BottleneckModal v-model="showDialog" :title="t('title')">
        <template #activator="{ props: activatorProps }">
            <slot name="activator" :props="activatorProps" />
        </template>
        <EscalationCreateForm
            :loading="isCreating || isEscalating"
            :escalation-list="escalationListUsers"
            @submit="handleCreate"
            @cancel="showDialog = false"
        />
    </BottleneckModal>
</template>
