<script setup lang="ts">
import { useField, useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { VForm, VTextField, VBtn, VTextarea } from 'vuetify/components';
import { VDateInput } from 'vuetify/labs/VDateInput';
import { toTypedSchema } from '@vee-validate/zod';
import { requirements } from '@/utils/formRequirements';
import { string, object, literal, date } from 'zod';
import type { NonNullableFields } from '@/utils';

export interface BottleneckFormValues {
    name: string;
    description: string;
    responsible: string;
    solution: string | null;
    resolutionDate: Date | null;
}

const props = defineProps<{
    initialValues?: BottleneckFormValues;
    disabled?: boolean;
    loading?: boolean;
}>();

const emit = defineEmits<{
    submit: [values: NonNullableFields<BottleneckFormValues>];
    cancel: [];
}>();

const { t } = useI18n({
    messages: {
        nl: {
            solution: 'Verwachte oplossing',
            resolution_date: 'Verwachte opleverdatum',
        },
        en: {
            solution: 'Expected solution',
            resolution_date: 'Expected delivery date',
        },
    },
});

const { bottleneck } = requirements;
const validationSchema = object({
    name: string()
        .min(
            bottleneck.name.min,
            t('error.minimum_characters', { count: bottleneck.name.min })
        )
        .max(
            bottleneck.name.max,
            t('error.maximum_characters', { max: bottleneck.name.max })
        ),
    description: string()
        .min(bottleneck.description.min, t('error.required_field'))
        .max(
            bottleneck.description.max,
            t('error.maximum_characters', { max: bottleneck.description.max })
        ),
    responsible: string().min(1, t('error.required_field')),
    solution: string()
        .min(bottleneck.solution.min, t('error.required_field'))
        .max(
            bottleneck.solution.max,
            t('error.maximum_characters', { max: bottleneck.solution.max })
        )
        .or(literal(null).refine(() => false, t('error.required_field'))),
    resolutionDate: date({ required_error: t('error.required_field') }).or(
        literal(null).refine(() => false, t('error.required_field'))
    ),
});

const { handleSubmit, meta } = useForm({
    validationSchema: toTypedSchema(validationSchema),
    initialValues: props.initialValues
        ? props.initialValues
        : {
              name: '',
              description: '',
              responsible: '',
              solution: null,
              resolutionDate: null,
          },
});

const name = useField<string>('name');
const description = useField<string>('description');
const responsible = useField<string>('responsible');
const solution = useField<string>('solution');
const resolutionDate = useField<Date | null>('resolutionDate');

const submit = handleSubmit((values) => {
    emit('submit', values as NonNullableFields<BottleneckFormValues>);
});
</script>

<template>
    <v-form class="d-flex flex-column ga-3" :disabled @submit.prevent="submit">
        <v-text-field
            v-model="name.value.value"
            :label="t('name')"
            :error-messages="name.errorMessage.value"
            autofocus
            @click:clear="name.value.value = ''"
        />
        <v-textarea
            v-model="description.value.value"
            :label="t('description')"
            :error-messages="description.errorMessage.value"
            persistent-placeholder
            rows="9"
        />
        <v-text-field
            v-model="responsible.value.value"
            :label="t('responsible')"
            :error-messages="responsible.errorMessage.value"
            @click:clear="responsible.value.value = ''"
        />
        <v-textarea
            v-model="solution.value.value"
            :label="t('solution')"
            :error-messages="solution.errorMessage.value"
            persistent-placeholder
            rows="9"
        />
        <v-date-input
            v-model="resolutionDate.value.value"
            :label="t('resolution_date')"
            :error-messages="resolutionDate.errorMessage.value"
            :min="new Date()"
            :disabled
            placeholder="dd-mm-yyyy"
            variant="outlined"
            prepend-icon=""
            hide-actions
            clearable
            readonly
            @click:clear="resolutionDate.setValue(null)"
        />
        <div class="d-flex justify-end ga-3 mt-5">
            <v-btn variant="text" @click="$emit('cancel')">
                {{ t('cancel') }}
            </v-btn>
            <v-btn
                type="submit"
                variant="elevated"
                :disabled="!meta.valid || !meta.dirty"
                :loading
            >
                {{ t('save') }}
            </v-btn>
        </div>
    </v-form>
</template>
