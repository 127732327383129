<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import { VTextField } from 'vuetify/components';
import { useMutation } from '@vue/apollo-composable';
import { useNotificationStore } from '@/stores/notification';
import { useField } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/zod';
import { graphql } from '@/gql';
import { string } from 'zod';
import { watchEffect } from 'vue';

const props = defineProps<{ bottleneckId: string }>();
const emit = defineEmits<{ success: []; loading: [value: boolean] }>();

const { t } = useI18n({
    messages: {
        nl: {
            delete: 'Verwijderen',
            delete_title: 'Permanent verwijderen',
            delete_confirmation:
                'Weet je zeker dat je deze bottleneck wilt verwijderen?',
            delete_success: 'Bottleneck succesvol verwijderd',
            delete_reason: 'Reden voor verwijdering',
        },
        en: {
            delete: 'Delete',
            delete_title: 'Permanently delete',
            delete_confirmation:
                'Are you sure you want to delete this bottleneck?',
            delete_success: 'Bottleneck successfully deleted',
            delete_reason: 'Reason for deletion',
        },
    },
});

const notificationStore = useNotificationStore();

const reason = useField(
    'reason',
    toTypedSchema(string().min(1, t('error.required_field')))
);

function handleClearReason() {
    reason.value.value = '';
}

function handleUpdate(dialogOpen: boolean) {
    if (dialogOpen) {
        reason.resetField();
    }
}

const { mutate: deleteBottleneck, loading: deleteBottleneckLoading } =
    useMutation(
        graphql(`
            mutation DeleteBottleneck($input: DeleteBottleneckInput!) {
                deleteBottleneck(input: $input) {
                    ok
                }
            }
        `),
        {
            refetchQueries: ['Deliverable'],
            awaitRefetchQueries: true,
        }
    );

watchEffect(() => {
    emit('loading', deleteBottleneckLoading.value);
});

async function handleDelete() {
    try {
        await deleteBottleneck({
            input: {
                bottleneckId: props.bottleneckId,
                deleteReason: reason.value.value,
            },
        });

        emit('success');
        notificationStore.add({
            type: 'success',
            message: t('delete_success'),
        });
    } catch {
        notificationStore.add({ type: 'error', message: t('delete_error') });
    }
}
</script>

<template>
    <ConfirmationDialog
        :title="t('delete_title')"
        :text="t('delete_confirmation')"
        :confirm-button-text="t('delete')"
        :confirm-button-disabled="!reason.meta.valid"
        persistent
        destructive
        @confirm="handleDelete"
        @update:model-value="handleUpdate"
    >
        <template #activator="{ props: activatorProps }">
            <slot
                name="activator"
                :props="activatorProps"
                :loading="deleteBottleneckLoading"
            />
        </template>
        <v-text-field
            v-model="reason.value.value"
            class="mt-8"
            :label="t('delete_reason')"
            :error-messages="reason.errorMessage.value"
            @click:clear="handleClearReason"
        />
    </ConfirmationDialog>
</template>
