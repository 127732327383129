<script lang="ts" setup>
import { computed } from 'vue';
import { VContainer, VRow, VCol } from 'vuetify/components';

import { useQuery } from '@vue/apollo-composable';
import { graphql } from '@/gql';
import { useUserSettingsStore } from '@/stores/user-settings';
import { useRouteParams } from '@vueuse/router';
import ProjectDescriptionPortfolioCard from '@/components/ProjectDescriptionPortfolioCard.vue';
import ProjectDescriptionTeamCard from './ProjectDescriptionTeamCard.vue';
import ProjectDescriptionPeriodCard from './ProjectDescriptionPeriodCard.vue';
import ProjectDescriptionMainCard from './ProjectDescriptionMainCard.vue';

const userSettings = useUserSettingsStore();

const projectId = useRouteParams<string>('id');

const variables = computed(() => ({
    projectId: projectId.value,
    portfolioId: userSettings.activePortfolio?.id ?? '',
}));

const { result, loading } = useQuery(
    graphql(`
        query ProjectDetails($projectId: ID!, $portfolioId: ID!) {
            project(projectId: $projectId, portfolioId: $portfolioId)
        }
    `),
    variables,
    {
        fetchPolicy: 'cache-first',
    }
);

const project = computed(() => result?.value?.project);

function concatName(userEntity: { firstName: string; lastName: string }) {
    return userEntity.firstName + ' ' + userEntity.lastName;
}

const portfolioCardInfo = computed(() => ({
    sourcePortfolio: project.value?.masterPortfolio.name ?? '',
    theme: project.value?.themes?.[0]?.name,
    program: project.value?.programs?.[0]?.name,
    department: project.value?.departments?.[0]?.name,
}));

function parseTeamMembers(teamMembers: string) {
    try {
        const y = JSON.parse(teamMembers);

        if (typeof y !== 'object' && !Array.isArray(y)) {
            return [];
        }

        return y.map((teamMember: { firstName: string; lastName: string }) =>
            concatName(teamMember)
        );
    } catch (error) {
        return [];
    }
}

const teamCardInfo = computed(() => ({
    programManager: project.value?.programManager
        ? concatName(project?.value?.programManager)
        : undefined,
    projectManager: project.value?.projectManager
        ? concatName(project?.value?.projectManager)
        : undefined,
    team: parseTeamMembers(project?.value?.projectTeam),
}));

const periodCardInfo = computed(() => ({
    startDate: project?.value?.startDate,
    endDate: project?.value?.endDate,
}));

const projectDescription = computed(() => ({
    description: project?.value?.description,
    goal: project?.value?.goal,
    scope: project?.value?.scope,
    result: project?.value?.result,
    risks: project?.value?.risks,
    stakeholders: project?.value?.stakeholders,
}));
</script>

<template>
    <v-container class="pt-6">
        <v-row>
            <v-col md="4" class="d-flex flex-column ga-6">
                <ProjectDescriptionPortfolioCard
                    :source-portfolio="portfolioCardInfo.sourcePortfolio"
                    :theme="portfolioCardInfo.theme"
                    :program="portfolioCardInfo.program"
                    :department="portfolioCardInfo.department"
                    :loading
                />

                <ProjectDescriptionTeamCard
                    :program-manager="teamCardInfo.programManager"
                    :project-manager="teamCardInfo.projectManager"
                    :team-members="teamCardInfo.team"
                    :loading
                />

                <ProjectDescriptionPeriodCard
                    :start-date="periodCardInfo.startDate"
                    :end-date="periodCardInfo.endDate"
                    :loading
                />
            </v-col>
            <v-col md="8">
                <ProjectDescriptionMainCard
                    v-if="!loading"
                    :description="projectDescription.description"
                    :goal="projectDescription.goal"
                    :scope="projectDescription.scope"
                    :result="projectDescription.result"
                    :risks="projectDescription.risks"
                    :stakeholders="projectDescription.stakeholders"
                />
            </v-col>
        </v-row>
    </v-container>
</template>
