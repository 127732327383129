<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { VDataTable } from 'vuetify/components';
import BottleneckTableRow from './BottleneckTableRow.vue';
import { BottleneckStatus as BottleneckStatusEnum } from '@/gql/graphql';

export interface BottleneckItem {
    id: string;
    name: string;
    solution: string | null;
    description: string;
    resolutionDate: string | null;
    status: BottleneckStatusEnum;
    responsible: string;
}

export interface BottleneckTableProps {
    items: BottleneckItem[];
    itemsPerPage?: number;
    loading?: boolean;
    canUpdate?: boolean;
    canDelete?: boolean;
    columns?: string[];
}

const {
    itemsPerPage = -1,
    canUpdate = false,
    canDelete = false,
    columns,
    items,
    loading,
} = defineProps<BottleneckTableProps>();

const { t } = useI18n({
    messages: {
        nl: {
            solution: 'Oplossing',
            resolution_date: 'Oplossingsdatum',
            no_results: 'Geen bottlenecks om te tonen',
        },
        en: {
            solution: 'Solution',
            resolution_date: 'Resolution date',
            no_results: 'No bottlenecks to show',
        },
    },
});

const sortBy = ref([{ key: 'status', order: 'asc' as const }]);

function sortStatus(a: BottleneckStatusEnum, b: BottleneckStatusEnum): number {
    const order = {
        [BottleneckStatusEnum.BOTTLENECK]: 0,
        [BottleneckStatusEnum.ESCALATION]: 1,
        [BottleneckStatusEnum.SOLVED]: 2,
    };

    return order[a] - order[b];
}

const headers = computed(() => {
    let _headers: Record<string, unknown>[] = [
        {
            title: t('name'),
            key: 'name',
        },
        {
            title: t('description'),
            key: 'description',
            sortable: false,
        },
        {
            title: t('solution'),
            key: 'solution',
            sortable: false,
        },
        {
            title: t('resolution_date'),
            key: 'resolutionDate',
        },
        {
            title: t('status'),
            key: 'status',
            sort: sortStatus,
        },
    ];

    if (columns?.length) {
        _headers = _headers.filter((header) =>
            columns.includes(header.key as string)
        );
    }

    if (canUpdate || canDelete) {
        _headers.push({
            title: t('actions'),
            key: 'actions',
            sortable: false,
            width: '64px',
            align: 'center' as const,
        });
    }

    return _headers;
});
</script>

<template>
    <v-data-table
        v-model:sort-by="sortBy"
        :headers
        :items
        :items-per-page
        :loading
        :no-data-text="t('no_results')"
    >
        <template #item="{ item }">
            <BottleneckTableRow :item :columns :can-update :can-delete />
        </template>
        <template v-if="itemsPerPage === -1" #bottom />
    </v-data-table>
</template>

<style lang="scss" scoped>
.bottleneck-table {
    &__text {
        white-space: pre-wrap;
        overflow-wrap: break-word;
    }
}
</style>
