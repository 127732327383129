<script setup lang="ts">
import { useField, useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { VForm, VBtn, VSelect } from 'vuetify/components';
import { toTypedSchema } from '@vee-validate/zod';
import type { NonNullableFields } from '@/utils';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';
import type { MoveProjectInput } from '@/gql/graphql';
import { graphql } from '@/gql';
import * as zod from 'zod';
import { useSelectablePortfolios } from '@/composables/portfolios';

export type ProjectMoveFormValues = Omit<MoveProjectInput, 'projectId'>;

const props = defineProps<{
    portfolioId: string;
    focusId: string;
    needId: string;
}>();

const emit = defineEmits<{
    submit: [values: NonNullableFields<ProjectMoveFormValues>];
    cancel: [];
}>();

const { t } = useI18n();

const { portfolios, loading: portfoliosLoading } = useSelectablePortfolios();

const validationSchema = toTypedSchema(
    zod.object({
        masterPortfolioId: zod.string(),
        focus: zod.string().min(1),
        need: zod.string().min(1),
    })
);

const { handleSubmit, meta } = useForm({
    validationSchema,
    initialValues: {
        masterPortfolioId: props.portfolioId,
        focus: props.focusId,
        need: props.needId,
    },
});

const masterPortfolioId = useField<string>('masterPortfolioId');
const focus = useField<string>('focus');
const need = useField<string>('need');

const submit = handleSubmit((values) => {
    emit('submit', {
        portfolioId: values.masterPortfolioId,
        focusId: values.focus,
        needId: values.need,
    } as NonNullableFields<ProjectMoveFormValues>);
});

const axisVariables = computed(() => ({
    portfolioId: masterPortfolioId.value.value ?? '',
}));

const { result: axisResult, loading: axisLoading } = useQuery(
    graphql(`
        query ProjectLocation($portfolioId: ID!) {
            portfolioAxis(portfolioId: $portfolioId) {
                items
            }
        }
    `),
    axisVariables
);

const focusItems = computed(
    () => axisResult?.value?.portfolioAxis.items.x.focus
);
const needsItems = computed(
    () => axisResult?.value?.portfolioAxis.items.y.needs
);

const loading = computed(() => Boolean(portfoliosLoading || axisLoading));

const onPortfolioChange = (value: string) => {
    console.log(value, props.portfolioId);
    focus.value.value = value === props.portfolioId ? props.focusId : '';
    need.value.value = value === props.portfolioId ? props.needId : '';
};
</script>

<template>
    <v-form
        class="d-flex flex-column ga-3"
        :disabled="loading"
        @submit.prevent="submit"
    >
        <v-select
            v-model="masterPortfolioId.value.value"
            name="masterPortfolioId"
            :label="t('master_portfolio')"
            :placeholder="t('master_portfolio')"
            :items="portfolios"
            :disabled="portfoliosLoading"
            item-title="name"
            item-value="id"
            @update:model-value="onPortfolioChange"
        />
        <v-select
            v-model="focus.value.value"
            name="focus"
            :label="t('focus')"
            :placeholder="t('focus')"
            :items="focusItems"
            :disabled="
                axisLoading ||
                props.portfolioId === masterPortfolioId.value.value
            "
            item-title="name"
            item-value="id"
        />
        <v-select
            v-model="need.value.value"
            name="need"
            :label="t('need')"
            :placeholder="t('need')"
            :items="needsItems"
            :disabled="
                axisLoading ||
                props.portfolioId === masterPortfolioId.value.value
            "
            item-title="name"
            item-value="id"
        />
        <div class="d-flex justify-end ga-3 mt-5">
            <v-btn variant="text" @click="$emit('cancel')">
                {{ t('cancel') }}
            </v-btn>
            <v-btn
                type="submit"
                variant="elevated"
                :disabled="!meta.valid || !meta.dirty"
            >
                {{ t('save') }}
            </v-btn>
        </div>
    </v-form>
</template>
