<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import BottleneckModal from './BottleneckModal.vue';
import BottleneckSolveForm, {
    type BottleneckSolveFormValues,
} from './BottleneckSolveForm.vue';
import { useMutation } from '@vue/apollo-composable';
import { graphql } from '@/gql';
import { useNotificationStore } from '@/stores/notification';
import type { NonNullableFields } from '@/utils';
import { BottleneckStatusType } from '@/gql/graphql';

const showDialog = defineModel<boolean>({ default: false });

const props = defineProps<{
    bottleneck: {
        id: string;
        name: string;
        description: string;
        responsible: string;
        solution: string | null;
        resolutionDate: string | null;
    };
}>();

const emit = defineEmits<{
    confirm: [];
    cancel: [];
}>();

const { t } = useI18n({
    messages: {
        nl: {
            title: 'Bottleneck oplossen',
            update_success: 'Bottleneck opgelost',
        },
        en: {
            title: 'Solve bottleneck',
            update_success: 'Bottleneck successfully solved',
        },
    },
});

const notificationStore = useNotificationStore();

const { mutate: updateBottleneckStatus, loading: isUpdating } = useMutation(
    graphql(`
        mutation UpdateBottleneckStatus(
            $statusInput: UpdateBottleneckStatusInput!
            $solutionInput: UpdateBottleneckInput!
        ) {
            updateBottleneck(input: $solutionInput) {
                result
            }
            updateBottleneckStatus(input: $statusInput) {
                ok
            }
        }
    `),
    {
        refetchQueries: [
            'Deliverable',
            'DeliverableBottlenecks',
            'ProjectPhases',
        ],
        awaitRefetchQueries: true,
    }
);

async function handleUpdate({
    solution,
}: NonNullableFields<BottleneckSolveFormValues>) {
    try {
        await updateBottleneckStatus({
            statusInput: {
                bottleneckId: props.bottleneck.id,
                status: BottleneckStatusType.SOLVED,
            },
            solutionInput: {
                bottleneckId: props.bottleneck.id,
                solution,
                name: props.bottleneck.name,
                description: props.bottleneck.description,
                responsible: props.bottleneck.responsible,
                resolutionDate: props.bottleneck.resolutionDate,
            },
        });

        showDialog.value = false;
        notificationStore.add({
            type: 'success',
            message: t('update_success'),
        });
        emit('confirm');
    } catch {
        notificationStore.add({ type: 'error', message: t('update_error') });
    }
}

function handleCancel() {
    showDialog.value = false;
    emit('cancel');
}
</script>

<template>
    <BottleneckModal v-model="showDialog" :title="t('title')">
        <template #activator="{ props: activatorProps }">
            <slot name="activator" :props="activatorProps" />
        </template>
        <BottleneckSolveForm
            :name="props.bottleneck.name"
            :initial-values="{ solution: props.bottleneck.solution }"
            :disabled="isUpdating"
            :loading="isUpdating"
            @submit="handleUpdate"
            @cancel="handleCancel"
        />
    </BottleneckModal>
</template>
