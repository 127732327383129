<script lang="ts" setup>
import PhaseModal from './PhaseModal.vue';
import PhaseForm, { type PhaseFormValues } from './PhaseForm.vue';
import { useMutation } from '@vue/apollo-composable';
import { graphql } from '@/gql';
import { useNotificationStore } from '@/stores/notification';
import { useI18n } from 'vue-i18n';

const showDialog = defineModel<boolean>({ default: false });

const props = defineProps<{
    phase?: {
        id: string;
        name: string;
        description?: string;
        startDate: string;
        endDate: string;
    } | null;
}>();

const { t } = useI18n({
    messages: {
        nl: {
            title: 'Fase bewerken',
            update_success: 'Fase succesvol bewerkt',
        },
        en: {
            title: 'edit phase',
            update_success: 'Phase successfully edited',
        },
    },
});

const notificationStore = useNotificationStore();

const { mutate: updatePhase, loading } = useMutation(
    graphql(`
        mutation UpdatePhase($input: UpdatePhaseInput!) {
            updatePhase(input: $input) {
                ok
            }
        }
    `),
    {
        refetchQueries: ['ProjectPhases'],
        awaitRefetchQueries: true,
    }
);

async function handleUpdate(values: PhaseFormValues) {
    try {
        await updatePhase({
            input: {
                phaseId: props.phase!.id,
                ...values,
            },
        });

        showDialog.value = false;

        notificationStore.add({
            type: 'success',
            message: t('update_success'),
        });
    } catch {
        notificationStore.add({
            type: 'error',
            message: t('update_error'),
        });
    }
}
</script>

<template>
    <PhaseModal v-model="showDialog" :title="t('title')">
        <template #activator="{ props: activatorProps }">
            <slot name="activator" :props="activatorProps" />
        </template>
        <PhaseForm
            :initial-values="props.phase"
            :loading
            @cancel="() => (showDialog = false)"
            @submit="handleUpdate"
        />
    </PhaseModal>
</template>
