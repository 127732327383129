<script setup lang="ts">
import { useField, useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { VForm, VTextField, VTextarea, VBtn } from 'vuetify/components';
import { toTypedSchema } from '@vee-validate/zod';
import { requirements } from '@/utils/formRequirements';
import {
    string,
    object,
    instanceof as instanceof_,
    NEVER,
    ZodIssueCode,
} from 'zod';
import FileInput from './FileInput.vue';

export interface DocumentFormValues {
    name: string;
    description: string;
    document: File;
}

defineProps<{ loading?: boolean; disabled?: boolean }>();

const emit = defineEmits<{
    submit: [values: DocumentFormValues];
    cancel: [];
}>();

const { t } = useI18n({
    messages: {
        nl: {
            upload_document_text: 'Sleep hier een @.lower:document naartoe',
        },
        en: {
            upload_document_text: 'Drop your @.lower:document here',
        },
    },
});

const { document: documentRequirements } = requirements;
const validationSchema = object({
    name: string()
        .min(documentRequirements.name.min, t('error.required_field'))
        .max(
            documentRequirements.name.max,
            t('error.maximum_characters', {
                max: documentRequirements.name.max,
            })
        ),
    description: string()
        .min(documentRequirements.description.min, t('error.required_field'))
        .max(
            documentRequirements.description.max,
            t('error.maximum_characters', {
                max: documentRequirements.description.max,
            })
        ),
    document: instanceof_(File)
        .nullable()
        .superRefine((arg, ctx): arg is File => {
            if (!(arg instanceof File)) {
                ctx.addIssue({
                    code: ZodIssueCode.custom,
                    message: t('error.file_selected'),
                    fatal: true,
                });
            }

            return NEVER;
        })
        .refine(
            (file) => !file || file.size <= 2000000,
            t('error.file_size_too_large', { size: '2MB' })
        ),
});

const { handleSubmit, meta } = useForm({
    validationSchema: toTypedSchema(validationSchema),
    initialValues: {
        name: '',
        description: '',
        document: null,
    },
});

const name = useField<string>('name');
const description = useField<string>('description');
const document = useField<File | null>('document');

const submit = handleSubmit((values) => {
    emit('submit', values);
});
</script>

<template>
    <v-form class="d-flex flex-column ga-3" :disabled @submit.prevent="submit">
        <v-text-field
            v-model="name.value.value"
            :label="t('name')"
            :error-messages="name.errorMessage.value"
            autofocus
            @click:clear="name.value.value = ''"
        />
        <v-textarea
            v-model="description.value.value"
            :label="t('description')"
            :error-messages="description.errorMessage.value"
            persistent-placeholder
            rows="9"
        />
        <FileInput
            v-model="document.value.value"
            :text="t('upload_document_text')"
            :max-file-size="2000000"
            :disabled
            :accepted-mime-types="[
                'application/msword',
                'application/pdf',
                'application/vnd.ms-excel',
                'application/vnd.ms-powerpoint',
                'application/vnd.oasis.opendocument.presentation',
                'application/vnd.oasis.opendocument.spreadsheet',
                'application/vnd.oasis.opendocument.text',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/zip',
                'audio/mpeg',
                'audio/wav',
                'image/gif',
                'image/jpeg',
                'image/jpeg',
                'image/png',
                'image/tiff',
                'image/tiff',
                'text/plain',
                'video/mpeg',
                'video/mpeg',
            ]"
        />
        <div class="d-flex justify-end ga-3 mt-5">
            <v-btn variant="text" @click="$emit('cancel')">
                {{ t('cancel') }}
            </v-btn>
            <v-btn
                type="submit"
                variant="elevated"
                :disabled="!meta.valid || !meta.dirty"
                :loading
            >
                {{ t('save') }}
            </v-btn>
        </div>
    </v-form>
</template>
