<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useForm, useField } from 'vee-validate';
import { string, object, literal, date, boolean } from 'zod';
import { requirements } from '@/utils/formRequirements';
import { toTypedSchema } from '@vee-validate/zod';
import {
    VBtn,
    VForm,
    VTextField,
    VTextarea,
    VAutocomplete,
    VCheckbox,
} from 'vuetify/components';
import { VDateInput } from 'vuetify/labs/VDateInput';
import type { User } from '@/gql/graphql';

export interface EscalationCreateFormValues {
    name: string;
    description: string;
    responsible: string;
    assignedUser: string;
    solution: string;
    escalationReason: string;
    resolutionDate: Date | null;
    sendNotification: boolean;
}

const { escalationList } = defineProps<{
    escalationList: Pick<User, 'fullName' | 'id'>[];
    loading?: boolean;
}>();

const emit = defineEmits<{
    submit: [values: EscalationCreateFormValues];
    cancel: [];
}>();

const { t } = useI18n({
    messages: {
        nl: {
            assigned_user: 'Verantwoordelijke voor de escalatie',
            solution: 'Oplossing',
            escalation_reason: 'Verzoek aan verantwoordelijke',
            resolution_date: 'Verwachte opleverdatum',
            send_notification: 'Stuur e-mail notificatie',
            save: 'Escalatie aanmaken',
        },
        en: {
            assigned_user: 'Responsible person for the escalation',
            solution: 'Solution',
            escalation_reason: 'Request to responsible person',
            resolution_date: 'Expected delivery date',
            send_notification: 'Send e-mail notification',
            save: 'Create escalation',
        },
    },
});

const { bottleneck } = requirements;
const validationSchema = object({
    name: string()
        .min(
            bottleneck.name.min,
            t('error.minimum_characters', { count: bottleneck.name.min })
        )
        .max(
            bottleneck.name.max,
            t('error.maximum_characters', { max: bottleneck.name.max })
        ),
    description: string()
        .min(bottleneck.description.min, t('error.required_field'))
        .max(
            bottleneck.description.max,
            t('error.maximum_characters', { max: bottleneck.description.max })
        )
        .or(literal(null).refine(() => false, t('error.required_field'))),
    assignedUser: string().min(1),
    solution: string()
        .min(
            bottleneck.solution.min,
            t('error.minimum_characters', { count: bottleneck.solution.min })
        )
        .max(
            bottleneck.solution.max,
            t('error.maximum_characters', { max: bottleneck.solution.max })
        )
        .or(literal(null).refine(() => false, t('error.required_field'))),
    escalationReason: string()
        .min(bottleneck.escalationReason.min, t('error.required_field'))
        .max(
            bottleneck.escalationReason.max,
            t('error.maximum_characters', {
                max: bottleneck.escalationReason.max,
            })
        )
        .or(literal(null).refine(() => false, t('error.required_field'))),
    resolutionDate: date({ required_error: t('error.required_field') }).or(
        literal(null).refine(() => false, t('error.required_field'))
    ),
    sendNotification: boolean().default(false),
});

const { handleSubmit, meta } = useForm({
    validationSchema: toTypedSchema(validationSchema),
});

const name = useField<string>('name');
const description = useField<string>('description');
const assignedUser = useField<string>('assignedUser');
const solution = useField<string>('solution');
const escalationReason = useField<string>('escalationReason');
const resolutionDate = useField<Date>('resolutionDate');
const sendNotification = useField<boolean>('sendNotification');

const submit = handleSubmit((values) => {
    // Fill the `responsible` field with the assignedUser.
    // This way we can skip the required `responsible` field to create a bottleneck.
    // We can't create an escalation without a bottleneck first.

    const v = {
        ...values,
        responsible:
            escalationList.find((user) => user.id === values.assignedUser)
                ?.fullName ?? '-',
    } as EscalationCreateFormValues;

    emit('submit', v);
});
</script>

<template>
    <v-form @submit.prevent="submit">
        <VTextField
            v-model="name.value.value"
            :label="t('name')"
            :error-messages="name.errorMessage.value"
            counter
            autofocus
            :clearable="false"
        />
        <VTextarea
            v-model="description.value.value"
            :label="t('description')"
            :error-messages="description.errorMessage.value"
            rows="5"
            @click:clear="description.value.value = ''"
        />

        <VAutocomplete
            v-model="assignedUser.value.value"
            :items="escalationList"
            :label="t('assigned_user')"
            item-title="fullName"
            item-value="id"
            :error-messages="assignedUser.errorMessage.value"
            :loading
        />

        <VTextarea
            v-model="solution.value.value"
            :label="t('solution')"
            :error-messages="solution.errorMessage.value"
            counter
            rows="5"
            @click:clear="solution.value.value = ''"
        />

        <VTextarea
            v-model="escalationReason.value.value"
            :label="t('escalation_reason')"
            :error-messages="escalationReason.errorMessage.value"
            rows="5"
            @click:clear="escalationReason.value.value = ''"
        />

        <VDateInput
            v-model="resolutionDate.value.value"
            :label="t('resolution_date')"
            :error-messages="resolutionDate.errorMessage.value"
            :min="new Date()"
            placeholder="dd-mm-yyyy"
            variant="outlined"
            prepend-icon=""
            hide-actions
            clearable
            readonly
            @click:clear="resolutionDate.setValue(new Date())"
        />

        <VCheckbox
            v-model="sendNotification.value.value"
            :label="t('send_notification')"
        />

        <div class="d-flex justify-end ga-3 mt-5">
            <v-btn variant="text" @click="$emit('cancel')">
                {{ t('cancel') }}
            </v-btn>
            <v-btn
                type="submit"
                variant="elevated"
                :disabled="!meta.valid"
                :loading
            >
                {{ t('save') }}
            </v-btn>
        </div>
    </v-form>
</template>
