<script lang="ts" setup>
import { VSelect, VBtn, VMenu, VList, VListItem } from 'vuetify/components';
import { useI18n } from 'vue-i18n';
import { ProjectStatus, type Project } from '@/gql/graphql';
import { ProjectStatus2 } from '@/types';
import ProjectDeleteDialog from './ProjectDeleteDialog.vue';
import ProjectConvertModal from './ProjectConvertModal.vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useMutation } from '@vue/apollo-composable';
import { graphql } from '@/gql';
import { useRouteParams } from '@vueuse/router';
import { useNotificationStore } from '@/stores/notification';
import { usePermissions } from '@/composables/permissions';
import confetti from 'canvas-confetti';
import { PolicyAction } from '@/types';
import { RouteName } from '@/router';
import { useUserSettingsStore } from '@/stores/user-settings';
import SubprojectConvertDialog from './SubprojectConvertDialog.vue';
import ProjectMoveModal from './ProjectMoveModal.vue';
import { useUserStore } from '@/stores/user';

const {
    loading,
    project,
    isSubproject,
    focusId = '',
    needId = '',
} = defineProps<{
    loading?: boolean;
    project?: Project;
    isSubproject?: boolean;
    focusId?: string;
    needId?: string;
}>();
const emit = defineEmits<{ (e: 'edit'): void }>();

const { hasPermission, isAdmin, isJigsEye } = usePermissions();

const router = useRouter();

const notificationStore = useNotificationStore();
const projectId = useRouteParams('id') ?? '';
const userSettingsStore = useUserSettingsStore();
const activePortfolioId = computed(
    () => userSettingsStore.activePortfolio?.id || ''
);
const { id: currentUserId } = useUserStore();

const { t } = useI18n({
    messages: {
        nl: {
            on_hold: 'On Hold',
            finished: 'Afgerond',
            active: 'Actief',
            approved_idea: 'Goedgekeurd @.lower:idea',
            edit: 'Bewerken',
            delete: 'Verwijderen',
            convert: 'Omzetten naar @.lower:subproject',
            convert_back: 'Omzetten naar @.lower:project',
            move: 'Wijzigen @.lower:portfolio',
            status_updated: 'Omgezet naar {status}',
            project_finished: '@:project is afgerond 🥳',
            project_on_hold: '@:project is on-hold gezet',
            project_active: '@:project is actief',
            error_updating_status: 'Updaten naar {status} mislukt',
        },
        en: {
            on_hold: 'On Hold',
            finished: 'Finished',
            active: 'Active',
            approved_idea: 'Approved @.lower:idea',
            edit: 'Edit',
            delete: 'Delete',
            convert: 'Convert to @.lower:subproject',
            convert_back: 'Convert to @.lower:project',
            move: 'Change @.lower:portfolio',
            status_updated: 'Type is now {status}',
            project_finished: '@:project finished 🥳',
            project_on_hold: '@:project has been put on hold',
            project_active: '@:project is active',
            error_updating_status: 'Failed to set to {status}',
        },
    },
});

function onProjectDeleted() {
    router.push({
        name: RouteName['dashboard.projects'],
    });
}

const status = defineModel<ProjectStatus>('status');
const status2 = defineModel<ProjectStatus2>('status2');

const statusOptions: {
    value: 'approved_idea' | 'idea' | 'project';
    title: string;
}[] = [
    {
        title: t('project'),
        value: 'project',
    },
    {
        title: t('approved_idea'),
        value: 'approved_idea',
    },
    {
        title: t('idea'),
        value: 'idea',
    },
];

const status2Options = computed<{ value: ProjectStatus2; title: string }[]>(
    () => [
        {
            title: t('active'),
            value: ProjectStatus2.ACTIVE,
        },
        {
            title: t('on_hold'),
            value: ProjectStatus2.ON_HOLD,
        },
        {
            title: t('finished'),
            value: ProjectStatus2.FINISHED,
            props: {
                disabled: !hasPermission(
                    PolicyAction.FINISH_PROJECT,
                    project?.id,
                    project?.masterPortfolio?.id
                ),
            },
        },
    ]
);

const { mutate: updateStatus, onError: onStatusUpdateError } = useMutation(
    graphql(`
        mutation UpdateStatus($input: UpdateProjectStatusInput!) {
            updateProjectStatus(input: $input) {
                ok
            }
        }
    `),
    {
        refetchQueries: [
            'Project',
            'ProjectDetails',
            'MetroProjects',
            'ProgramProjects',
            'Program',
            'Projects',
            'Ideas',
        ],
    }
);

async function handleStatusUpdate(value: ProjectStatus) {
    try {
        await updateStatus({
            input: {
                projectId: projectId.value,
                status: value,
            },
        });
        notificationStore.add({
            type: 'success',
            message: t('status_updated', { status: t(value) }),
        });
    } catch (error) {
        notificationStore.add({
            type: 'error',
            message: t('error_updating_status', { status: t(value) }),
        });
    }
}

onStatusUpdateError((value) => {
    alert(value);
});

const { mutate: finishProject } = useMutation(
    graphql(`
        mutation FinishProject($input: FinishProjectInput!) {
            finishProject(input: $input) {
                ok
            }
        }
    `),
    {
        refetchQueries: [
            'Project',
            'ProjectDetails',
            'MetroProjects',
            'ProgramProjects',
            'Program',
            'Projects',
            'Ideas',
        ],
    }
);

const { mutate: holdProject } = useMutation(
    graphql(`
        mutation HoldProject($input: HoldProjectInput!) {
            holdProject(input: $input) {
                project {
                    onHold
                }
            }
        }
    `),
    {
        refetchQueries: [
            'Project',
            'ProjectDetails',
            'MetroProjects',
            'ProgramProjects',
            'Program',
            'Projects',
            'Ideas',
        ],
    }
);

async function handleStatus2Update(value: ProjectStatus2) {
    try {
        if (value === ProjectStatus2.FINISHED) {
            await finishProject({
                input: {
                    projectId: projectId.value,
                },
            });
            confetti();
            notificationStore.add({
                type: 'success',
                message: t('project_finished'),
            });
        }

        if (value === ProjectStatus2.ON_HOLD) {
            await holdProject({
                input: {
                    projectId: projectId.value,
                },
            });
            notificationStore.add({
                type: 'success',
                message: t('project_on_hold'),
            });
        }

        if (value === ProjectStatus2.ACTIVE) {
            await holdProject({
                input: {
                    projectId: projectId.value,
                },
            });
            notificationStore.add({
                type: 'success',
                message: t('project_active'),
            });
        }
    } catch (error) {
        notificationStore.add({
            type: 'error',
            message: 'Something went wrong',
        });
    }
}

const canBeConverted = computed(
    () =>
        !isSubproject &&
        project &&
        project.masterPortfolio?.id === activePortfolioId.value &&
        hasPermission(
            PolicyAction.CONVERT_SUBPROJECT,
            project.id,
            project.masterPortfolio?.id
        )
);

const canBeConvertedBack = computed(
    () =>
        isSubproject &&
        project &&
        project.masterPortfolio?.id === activePortfolioId.value &&
        hasPermission(
            PolicyAction.CONVERT_SUBPROJECT,
            project.id,
            project.masterPortfolio?.id
        )
);
</script>
<template>
    <v-select
        v-model="status"
        :disabled="
            !hasPermission(
                PolicyAction.UPDATE_PROJECT_STATUS,
                project?.id,
                project?.masterPortfolio?.id
            )
        "
        theme="dark"
        hide-details
        :items="statusOptions"
        max-width="180px"
        @update:model-value="handleStatusUpdate"
    ></v-select>
    <v-select
        v-model="status2"
        :disabled="
            !hasPermission(
                PolicyAction.HOLD_PROJECT,
                project?.id,
                project?.masterPortfolio?.id
            )
        "
        theme="dark"
        hide-details
        :items="status2Options"
        max-width="180px"
        @update:model-value="handleStatus2Update"
    ></v-select>
    <v-menu
        v-if="
            hasPermission(
                PolicyAction.PROJECT_WRITE,
                project?.id,
                project?.masterPortfolio?.id || ''
            )
        "
        :menu-props="{ closeOnContentClick: true }"
    >
        <template #activator="{ props: activatorProps }">
            <v-btn
                theme="dark"
                icon="$more"
                variant="outlined"
                :style="{ '--v-btn-height': '2.25rem' }"
                v-bind="activatorProps"
            ></v-btn>
        </template>
        <v-list>
            <v-list-item :disabled="loading" @click="emit('edit')">
                {{ t('edit') }}
            </v-list-item>
            <template v-if="typeof projectId === 'string'">
                <ProjectConvertModal
                    v-if="canBeConverted"
                    :project-id
                    :portfolio-id="activePortfolioId"
                >
                    <template #activator="{ props }">
                        <v-list-item v-bind="props">
                            {{ t('convert') }}
                        </v-list-item>
                    </template>
                </ProjectConvertModal>
            </template>
            <template v-if="typeof projectId === 'string'">
                <SubprojectConvertDialog v-if="canBeConvertedBack" :project-id>
                    <template #activator="{ props }">
                        <v-list-item v-bind="props">{{
                            t('convert_back')
                        }}</v-list-item>
                    </template>
                </SubprojectConvertDialog>
            </template>
            <template v-if="typeof projectId === 'string'">
                <ProjectMoveModal
                    :project-id
                    :focus-id
                    :need-id
                    :portfolio-id="String(project?.masterPortfolio?.id)"
                >
                    <template #activator="{ props }">
                        <v-list-item v-bind="props">
                            {{ t('move') }}
                        </v-list-item>
                    </template>
                </ProjectMoveModal>
            </template>
            <template v-if="typeof projectId === 'string'">
                <ProjectDeleteDialog
                    v-if="hasPermission(PolicyAction.PROJECT_DELETE, projectId)"
                    :project-id
                    @success="onProjectDeleted"
                >
                    <template #activator="{ props }">
                        <v-list-item v-bind="props">{{
                            t('delete')
                        }}</v-list-item>
                    </template>
                </ProjectDeleteDialog>
            </template>
        </v-list>
    </v-menu>
</template>
