<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { formatDate } from '@/utils/date';
import type { BottleneckItem } from './BottleneckTable.vue';
import BottleneckDeleteDialog from './BottleneckDeleteDialog.vue';
import BottleneckEditModal from './BottleneckEditModal.vue';
import BottleneckEscalateModal from './BottleneckEscalateModal.vue';
import BottleneckStatusSelector from './BottleneckStatusSelector.vue';
import BottleneckStatusComponent from './BottleneckStatus.vue';
import BottleneckSolveModal from './BottleneckSolveModal.vue';
import { ref } from 'vue';

const { item, canDelete, canUpdate, columns } = defineProps<{
    item: BottleneckItem;
    canDelete: boolean;
    canUpdate: boolean;
    columns?: string[];
}>();

const { t } = useI18n({
    messages: {
        nl: {
            edit: 'Bewerken',
            delete: 'Verwijderen',
        },
        en: {
            edit: 'Edit',
            delete: 'Delete',
        },
    },
});

const isShowingSolveModal = ref(false);
const isShowingEscalateModal = ref(false);
</script>

<template>
    <BottleneckSolveModal v-model="isShowingSolveModal" :bottleneck="item" />
    <BottleneckEscalateModal
        v-model="isShowingEscalateModal"
        :bottleneck="item"
    />
    <tr>
        <td
            v-if="!columns || (columns && columns.includes('name'))"
            class="v-data-table__td"
        >
            <span class="text-no-wrap">{{ item.name }}</span>
        </td>
        <td
            v-if="!columns || columns?.includes('description')"
            class="v-data-table__td"
        >
            <div class="bottleneck-table__text py-2">
                {{ item.description || '-' }}
            </div>
        </td>
        <td
            v-if="!columns || columns?.includes('solution')"
            class="v-data-table__td"
        >
            <div class="bottleneck-table__text py-2">
                {{ item.solution || '-' }}
            </div>
        </td>
        <td
            v-if="!columns || columns?.includes('resolutionDate')"
            class="v-data-table__td"
        >
            {{ item.resolutionDate ? formatDate(item.resolutionDate) : '-' }}
        </td>
        <td
            v-if="!columns || columns?.includes('status')"
            class="v-data-table__td"
        >
            <BottleneckStatusSelector
                v-if="canUpdate"
                :bottleneck-id="item.id"
                :selected="item.status"
                @solve-bottleneck="isShowingSolveModal = true"
                @solve-escalation="isShowingSolveModal = true"
                @escalate-bottleneck="isShowingEscalateModal = true"
            />

            <BottleneckStatusComponent v-else :status="item.status" />
        </td>
        <td class="v-data-table__td">
            <div class="d-flex justify-center">
                <BottleneckEditModal v-if="canUpdate" :bottleneck="item">
                    <template #activator="{ props: activatorProps }">
                        <v-btn-icon
                            size="x-small"
                            icon="$edit"
                            v-bind="activatorProps"
                        />
                    </template>
                </BottleneckEditModal>
                <BottleneckDeleteDialog
                    v-if="canDelete"
                    :bottleneck-id="item.id"
                >
                    <template
                        #activator="{
                            props: activatorProps,
                            loading: deleting,
                        }"
                    >
                        <v-btn-icon
                            size="x-small"
                            icon="$delete"
                            :aria-label="t('delete')"
                            :loading="deleting"
                            v-bind="activatorProps"
                        />
                    </template>
                </BottleneckDeleteDialog>
            </div>
        </td>
    </tr>
</template>
