<script lang="ts" setup>
import ProjectDescriptionCard from './ProjectDescriptionCard.vue';
import { VList, VListItem } from 'vuetify/components';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

const { t } = useI18n({
    messages: {
        nl: {
            title: 'Team',
            programManager: '@:program_manager',
            projectManager: '@:project_manager',
            teamMembers: 'Teamleden',
            no_program_manager: 'Geen @.lower:program_manager',
            no_project_manager: 'Geen @.lower:project_manager',
            no_team_members: 'Geen team',
        },
        en: {
            title: 'Team',
            programManager: '@:program_manager',
            projectManager: '@:project_manager',
            teamMembers: 'Team members',
            no_program_manager: 'No @.lower:program_manager',
            no_project_manager: 'No @.lower:project_manager',
            no_team_members: 'No team',
        },
    },
});

const props = defineProps<{
    loading: boolean;
    programManager?: string;
    projectManager?: string;
    teamMembers?: string[];
}>();

const items = computed(() => ({
    programManager: props.programManager ?? t('no_program_manager'),
    projectManager: props.projectManager ?? t('no_project_manager'),
    teamMembers:
        props.teamMembers!.length > 0
            ? props.teamMembers?.join(', ')
            : t('no_team_members'),
}));
</script>

<template>
    <ProjectDescriptionCard :title="t('title')" icon="$team" :loading>
        <v-list bg-color="transparent">
            <v-list-item
                v-for="[key, value] of Object.entries(items)"
                :key
                style="padding-inline: none"
                :title="t(key)"
                :subtitle="value"
            />
        </v-list>
    </ProjectDescriptionCard>
</template>
