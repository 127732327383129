<script lang="ts" setup>
import { VBtn, VIcon } from 'vuetify/components';
import type { DeliverableTableItem } from './DeliverableTable.vue';
import { format, parseISO } from 'date-fns';
import { usePermissions } from '@/composables/permissions';
import { PolicyAction } from '@/types';
import PhaseDeleteDialog from '@/components/PhaseDeleteDialog.vue';
import PhaseEditModal from './PhaseEditModal.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n({
    messages: {
        nl: {
            delete: 'Verwijderen',
        },
        en: {
            delete: 'Delete',
        },
    },
});

const { hasPermission } = usePermissions();

interface PhaseTableRowProps {
    isExpanded: boolean;
    item: DeliverableTableItem;
}
defineProps<PhaseTableRowProps>();

defineEmits<{ (e: 'toggleExpand'): void; (e: 'delete'): void }>();
</script>

<template>
    <tr class="v-data-table__tr">
        <td class="v-data-table__td">
            <v-btn
                v-if="item.deliverables?.length"
                variant="text"
                color="neutral"
                class="text-body-medium"
                @click="$emit('toggleExpand')"
            >
                {{ item.name }}
                <v-icon>{{
                    isExpanded ? 'expand_less' : 'expand_more'
                }}</v-icon>
            </v-btn>
            <span v-else class="text-body-medium text-color-neutral pl-6">
                {{ item.name }}
            </span>
        </td>
        <td class="v-data-table__td">
            <!-- deliverables-->
            <VTag color="primary">
                {{ item.deliverables.length }}
            </VTag>
        </td>
        <td class="v-data-table__td">
            <!-- type-->
        </td>
        <td class="v-data-table__td">
            <!-- status -->
        </td>
        <td class="v-data-table__td">
            <template v-if="item.startDate">
                {{ format(parseISO(item.startDate), 'd-M-yyyy') }}
            </template>
        </td>
        <td class="v-data-table__td">
            <template v-if="item.endDate">
                {{ format(parseISO(item.endDate), 'd-M-yyyy') }}
            </template>
        </td>
        <td class="v-data-table__td pl-2">
            <div class="d-flex justify-end">
                <PhaseEditModal
                    v-if="
                        hasPermission(
                            PolicyAction.PROJECT_WRITE,
                            item?.project?.id,
                            item?.project?.masterPortfolioId ?? undefined
                        )
                    "
                    :phase="{
                        id: item.id,
                        name: item.name,
                        description: item.description,
                        startDate: item.startDate,
                        endDate: item.endDate,
                    }"
                >
                    <template #activator="{ props }">
                        <v-btn-icon
                            size="x-small"
                            icon="$edit"
                            :aria-label="t('delete')"
                            v-bind="props"
                        />
                    </template>
                </PhaseEditModal>
                <PhaseDeleteDialog
                    v-if="
                        hasPermission(
                            PolicyAction.PROJECT_WRITE,
                            item?.project?.id,
                            item?.project?.masterPortfolioId ?? undefined
                        )
                    "
                    :phase-id="item.id"
                    @success="$emit('delete')"
                >
                    <template #activator="{ props, loading }">
                        <v-btn-icon
                            size="x-small"
                            icon="$delete"
                            :aria-label="t('delete')"
                            :loading
                            v-bind="props"
                        />
                    </template>
                </PhaseDeleteDialog>
            </div>
        </td>
    </tr>
</template>
