<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { watchEffect } from 'vue';
import { useMutation } from '@vue/apollo-composable';
import { useNotificationStore } from '@/stores/notification';
import { graphql } from '@/gql';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';

const props = defineProps<{ documentId: string }>();
const emit = defineEmits<{ success: []; loading: [value: boolean] }>();

const { t } = useI18n({
    messages: {
        nl: {
            delete: 'Verwijderen',
            delete_title: 'Permanent verwijderen',
            delete_confirmation:
                'Weet je zeker dat je dit @.lower:document wilt verwijderen?',
            delete_success: '@:document succesvol verwijderd',
        },
        en: {
            delete: 'Delete',
            delete_title: 'Permanently delete',
            delete_confirmation:
                "Are you sure you want to delete this @.lower:{'document'}?",
            delete_success: '@:document successfully deleted',
        },
    },
});

const notificationStore = useNotificationStore();

const { mutate: deleteDocument, loading: isDeleting } = useMutation(
    graphql(`
        mutation DeleteDocument($input: DeleteDocumentInput!) {
            deleteDocument(input: $input) {
                ok
            }
        }
    `),
    {
        refetchQueries: ['Deliverable', 'ProjectDocuments'],
        awaitRefetchQueries: true,
    }
);

watchEffect(() => {
    emit('loading', isDeleting.value);
});

async function handleDelete() {
    try {
        await deleteDocument({ input: { documentId: props.documentId } });

        emit('success');
        notificationStore.add({
            type: 'success',
            message: t('delete_success'),
        });
    } catch {
        notificationStore.add({ type: 'error', message: t('delete_error') });
    }
}
</script>

<template>
    <ConfirmationDialog
        :title="t('delete_title')"
        :text="t('delete_confirmation')"
        :confirm-button-text="t('delete')"
        persistent
        destructive
        @confirm="handleDelete"
    >
        <template #activator="{ props: activatorProps }">
            <slot
                name="activator"
                :props="activatorProps"
                :loading="isDeleting"
            />
        </template>
    </ConfirmationDialog>
</template>
