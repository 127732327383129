<script setup lang="ts">
import { useField, useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import {
    VForm,
    VBtn,
    VTextarea,
    VAutocomplete,
    VCheckbox,
} from 'vuetify/components';
import { toTypedSchema } from '@vee-validate/zod';
import { requirements } from '@/utils/formRequirements';
import { string, object, literal, boolean } from 'zod';
import type { NonNullableFields } from '@/utils';
import type { User } from '@/gql/graphql';

export interface BottleneckEscalateFormValues {
    solution: string;
    assignedUser: string | null;
    escalationReason: string;
    sendNotification: boolean;
}

const props = defineProps<{
    escalationList: Pick<User, 'fullName' | 'id'>[];
    initialValues?: {
        solution: string | null;
        assignedUser: string;
        escalationReason: string;
        sendNotification: boolean;
    };
    disabled?: boolean;
    loading?: boolean;
}>();

const emit = defineEmits<{
    submit: [values: NonNullableFields<BottleneckEscalateFormValues>];
    cancel: [];
}>();

const { t } = useI18n({
    messages: {
        nl: {
            escalate: 'Escaleer',
            escalation_reason: 'Verzoek aan verantwoordelijke',
            send_notification: 'Stuur e-mail notificatie',
        },
        en: {
            escalate: 'Escalate',
            escalation_reason: 'Request to responsible person',
            send_notification: 'Send email notification',
        },
    },
});

const { bottleneck } = requirements;

const validationSchema = object({
    assignedUser: string().min(1),
    escalationReason: string()
        .min(bottleneck.escalationReason.min, t('error.required_field'))
        .max(
            bottleneck.escalationReason.max,
            t('error.maximum_characters', {
                max: bottleneck.escalationReason.max,
            })
        ),
    solution: string()
        .min(bottleneck.solution.min, t('error.required_field'))
        .max(
            bottleneck.solution.max,
            t('error.maximum_characters', { max: bottleneck.solution.max })
        )
        .or(literal(null).refine(() => false, t('error.required_field'))),
    sendNotification: boolean(),
});

const { handleSubmit, meta } = useForm({
    validationSchema: toTypedSchema(validationSchema),
    initialValues: props.initialValues
        ? props.initialValues
        : {
              solution: '',
              assignedUser: '',
              escalationReason: '',
              sendNotification: false,
          },
});

const solution = useField<string>('solution');
const assignedUser = useField<string>('assignedUser');
const escalationReason = useField<string>('escalationReason');
const sendNotification = useField<boolean>('sendNotification');

const submit = handleSubmit((values) => {
    emit('submit', values as NonNullableFields<BottleneckEscalateFormValues>);
});
</script>

<template>
    <v-form class="d-flex flex-column ga-3" :disabled @submit.prevent="submit">
        <v-autocomplete
            v-model="assignedUser.value.value"
            :items="escalationList"
            :label="t('responsible')"
            item-title="fullName"
            item-value="id"
            :error-messages="assignedUser.errorMessage.value"
            :loading
            autofocus
        />
        <v-textarea
            v-model="solution.value.value"
            :label="t('description')"
            :error-messages="solution.errorMessage.value"
            persistent-placeholder
            rows="5"
        />
        <v-textarea
            v-model="escalationReason.value.value"
            :label="t('escalation_reason')"
            :error-messages="escalationReason.errorMessage.value"
            persistent-placeholder
            rows="5"
        ></v-textarea>
        <v-checkbox
            v-model="sendNotification.value.value"
            :label="t('send_notification')"
        />
        <div class="d-flex justify-end ga-3 mt-5">
            <v-btn variant="text" @click="$emit('cancel')">
                {{ t('cancel') }}
            </v-btn>
            <v-btn
                type="submit"
                variant="elevated"
                :disabled="!meta.valid"
                :loading
            >
                {{ t('escalate') }}
            </v-btn>
        </div>
    </v-form>
</template>
