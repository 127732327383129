<script setup lang="ts">
import { graphql } from '@/gql';
import { BottleneckStatus } from '@/gql/graphql';
import { useNotificationStore } from '@/stores/notification';
import { useMutation } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import StatusSelector from './StatusSelector.vue';
import { computed, toRaw } from 'vue';

const { bottleneckId, selected } = defineProps<{
    bottleneckId: string;
    selected: BottleneckStatus;
}>();

const emit = defineEmits<{
    solveBottleneck: [];
    solveEscalation: [];
    escalateBottleneck: [];
}>();

const initialSelected = toRaw(selected);

const { t } = useI18n({
    messages: {
        nl: {
            bottleneck: 'Bottleneck',
            solved: 'Opgelost',
            escalation: 'Escalatie',
            update_success: 'Bottleneck succesvol bewerkt',
        },
        en: {
            bottleneck: 'Bottleneck',
            solved: 'Solved',
            escalation: 'Escalation',
            update_success: 'Bottleneck successfully edited',
        },
    },
});

const notificationStore = useNotificationStore();

const items = computed(() => [
    { title: t('bottleneck'), value: BottleneckStatus.BOTTLENECK },
    { title: t('solved'), value: BottleneckStatus.SOLVED },
    { title: t('escalation'), value: BottleneckStatus.ESCALATION },
]);

const {
    mutate: updateBottleneckStatus,
    loading: updateBottleneckStatusLoading,
} = useMutation(
    graphql(`
        mutation SetBottleneckToPending($input: UpdateBottleneckStatusInput!) {
            updateBottleneckStatus(input: $input) {
                ok
            }
        }
    `),
    {
        refetchQueries: ['Deliverable', 'ProjectPhases'],
        awaitRefetchQueries: true,
    }
);

async function handleUpdate(status: BottleneckStatus) {
    if (status === initialSelected) return;

    if (
        // From bottleneck to solved
        initialSelected === BottleneckStatus.BOTTLENECK &&
        status === BottleneckStatus.SOLVED
    ) {
        emit('solveBottleneck');
    }

    if (
        // From bottleneck to escalation
        initialSelected === BottleneckStatus.BOTTLENECK &&
        status === BottleneckStatus.ESCALATION
    ) {
        emit('escalateBottleneck');
    }

    if (
        // From escalation to bottleneck
        initialSelected === BottleneckStatus.ESCALATION &&
        status === BottleneckStatus.BOTTLENECK
    ) {
        try {
            await updateBottleneckStatus({
                input: { bottleneckId, status },
            });

            notificationStore.add({
                type: 'success',
                message: t('update_success'),
            });
        } catch {
            notificationStore.add({
                type: 'error',
                message: t('update_error'),
            });
        }
    }

    if (
        // From escalation to solved
        initialSelected === BottleneckStatus.ESCALATION &&
        status === BottleneckStatus.SOLVED
    ) {
        emit('solveEscalation');
    }
}
</script>

<template>
    <StatusSelector
        width="125"
        :items
        :model-value="selected"
        :loading="updateBottleneckStatusLoading"
        @update:model-value="handleUpdate"
    />
</template>
