<script setup lang="ts">
import { useField, useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import {
    VForm,
    VTextField,
    VTextarea,
    VRow,
    VCol,
    VBtn,
} from 'vuetify/components';
import { toTypedSchema } from '@vee-validate/zod';
import { requirements } from '@/utils/formRequirements';
import { string, object, date } from 'zod';
import type { CreatePreviewInput } from '@/gql/graphql';
import { useDateTime } from '@/composables/datetime';
import { VDateInput } from 'vuetify/labs/VDateInput';

export type PreviewFormValues = Pick<
    CreatePreviewInput,
    'name' | 'description' | 'date'
>;

const props = defineProps<{
    initialValues?: (PreviewFormValues & { id?: string }) | null;
    disabled?: boolean;
    loading?: boolean;
}>();

const emit = defineEmits<{
    submit: [values: PreviewFormValues];
    cancel: [];
}>();

const { t } = useI18n();

const { preview } = requirements;
const validationSchema = object({
    name: string({
        invalid_type_error: t('error.required_field'),
    })
        .min(preview.name.min, t('error.required_field'))
        .max(
            preview.name.max,
            t('error.maximum_characters', { max: preview.name.max })
        ),
    description: string({
        invalid_type_error: t('error.required_field'),
    })
        .min(preview.description.min, t('error.required_field'))
        .max(
            preview.description.max,
            t('error.maximum_characters', { max: preview.description.max })
        ),
    previewDate: date(),
});

const { handleSubmit, meta } = useForm({
    validationSchema: toTypedSchema(validationSchema),
    initialValues: props.initialValues
        ? {
              ...props.initialValues,
              previewDate: new Date(props.initialValues.date),
          }
        : {
              name: '',
              description: '',
              previewDate: new Date(),
          },
});

const name = useField<string | null>('name');
const description = useField<string | null>('description');
const previewDate = useField<string>('previewDate');

const submit = handleSubmit((values) => {
    const { formattedDate } = useDateTime(values.previewDate);

    if (!formattedDate.value) return;

    emit('submit', {
        date: formattedDate.value,
        name: values.name,
        description: values.description,
    });
});
</script>

<template>
    <v-form class="d-flex flex-column ga-3" :disabled @submit.prevent="submit">
        <v-row>
            <v-col cols="12">
                <v-text-field
                    v-model="name.value.value"
                    :placeholder="t('name')"
                    :error-messages="name.errorMessage.value"
                    :counter="preview.name.max"
                    :maxlength="preview.name.max"
                    :label="t('name')"
                    name="name"
                    autofocus
                ></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-textarea
                    v-model="description.value.value"
                    name="description"
                    :label="t('description')"
                    :placeholder="t('description')"
                    :counter="preview.description.max"
                    :maxlength="preview.description.max"
                    :error-messages="description.errorMessage.value"
                    :clearable="false"
                    persistent-placeholder
                ></v-textarea>
            </v-col>
            <v-col cols="12">
                <v-date-input
                    v-model="previewDate.value.value"
                    :label="t('date')"
                    :error-messages="previewDate.errorMessage.value"
                    variant="outlined"
                    prepend-icon=""
                    prepend-inner-icon="$date"
                    placeholder="dd-mm-yyyy"
                ></v-date-input>
            </v-col>
            <v-col cols="12">
                <div class="d-flex justify-end ga-3">
                    <v-btn variant="text" @click="$emit('cancel')">
                        {{ t('cancel') }}
                    </v-btn>
                    <v-btn
                        type="submit"
                        variant="elevated"
                        :disabled="!meta.valid || !meta.dirty"
                        :loading
                    >
                        {{ t('save') }}
                    </v-btn>
                </div>
            </v-col>
        </v-row>
    </v-form>
</template>
