<script setup lang="ts">
import { VCardText } from 'vuetify/components';
import AppModal from '@/components/AppModal.vue';
import PhaseForm, { type PhaseFormValues } from '@/components/PhaseForm.vue';
import { useI18n } from 'vue-i18n';
import { useNotificationStore } from '@/stores/notification';
import { useMutation } from '@vue/apollo-composable';
import { graphql } from '@/gql';

const { t } = useI18n({
    messages: {
        nl: {
            title: 'Fase aanmaken',
            create_success: 'Fase succesvol toegevoegd',
        },
        en: {
            title: 'Create a phase',
            create_success: 'Successfully added phase',
        },
    },
});

const notificationStore = useNotificationStore();
const props = defineProps<{ projectId: string }>();

const showDialog = defineModel<boolean>({ default: false });

const { mutate: createPhase } = useMutation(
    graphql(`
        mutation CreatePhase($input: CreatePhaseInput!) {
            createPhase(input: $input) {
                phase {
                    id
                }
            }
        }
    `),
    {
        refetchQueries: ['ProjectPhases'],
        awaitRefetchQueries: true,
    }
);

async function onSubmit(values: PhaseFormValues) {
    try {
        await createPhase({
            input: {
                name: values.name,
                startDate: values.startDate,
                description: 'REPLACE_ME',
                endDate: values.endDate,
                projectId: props.projectId,
            },
        });

        showDialog.value = false;

        notificationStore.add({
            type: 'success',
            message: t('create_success'),
        });
    } catch {
        notificationStore.add({
            type: 'error',
            message: t('create_error'),
        });
    }
}
</script>

<template>
    <AppModal
        v-model="showDialog"
        :title="t('title')"
        :width="744"
        height="auto"
    >
        <template #activator="{ props: activatorProps }">
            <slot name="activator" :props="activatorProps" />
        </template>
        <v-card-text class="pa-8">
            <PhaseForm @submit="onSubmit" @cancel="showDialog = false" />
        </v-card-text>
    </AppModal>
</template>
