<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import { useMutation } from '@vue/apollo-composable';
import { useNotificationStore } from '@/stores/notification';
import { graphql } from '@/gql';
import { watchEffect } from 'vue';

const { previewId } = defineProps<{ previewId: string }>();
const emit = defineEmits<{ success: []; loading: [value: boolean] }>();

const { t } = useI18n({
    messages: {
        nl: {
            delete: 'Verwijderen',
            delete_title: 'Permanent verwijderen',
            delete_confirmation:
                'Weet je zeker dat je deze @.lower:preview wilt verwijderen?',
            delete_success: '@:preview succesvol verwijderd',
        },
        en: {
            delete: 'Delete',
            delete_title: 'Permanently delete',
            delete_confirmation:
                "Are you sure you want to delete this @.lower:{'preview'}?",
            delete_success: '@:preview successfully deleted',
        },
    },
});

const notificationStore = useNotificationStore();

const { mutate: deletePreview, loading: deletePreviewLoading } = useMutation(
    graphql(`
        mutation DeletePreview($input: DeletePreviewInput!) {
            deletePreview(input: $input) {
                ok
            }
        }
    `),
    {
        refetchQueries: ['ProjectPreviews'],
        awaitRefetchQueries: true,
    }
);

watchEffect(() => {
    emit('loading', deletePreviewLoading.value);
});

async function handleDelete() {
    try {
        await deletePreview({ input: { previewId } });

        emit('success');
        notificationStore.add({
            type: 'success',
            message: t('delete_success'),
        });
    } catch {
        notificationStore.add({ type: 'error', message: t('delete_error') });
    }
}
</script>

<template>
    <ConfirmationDialog
        :title="t('delete_title')"
        :text="t('delete_confirmation')"
        :confirm-button-text="t('delete')"
        persistent
        @confirm="handleDelete"
    >
        <template #activator="{ props: activatorProps }">
            <slot
                name="activator"
                :props="activatorProps"
                :loading="deletePreviewLoading"
            />
        </template>
    </ConfirmationDialog>
</template>
