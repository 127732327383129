<script setup lang="ts">
import { VCardText } from 'vuetify/components';
import AppModal from '@/components/AppModal.vue';
import { useI18n } from 'vue-i18n';
import { useNotificationStore } from '@/stores/notification';
import { useMutation } from '@vue/apollo-composable';
import { graphql } from '@/gql';
import ProjectConvertForm, {
    type ProjectConvertFormValues,
} from './ProjectConvertForm.vue';

const { t } = useI18n({
    messages: {
        nl: {
            title: '@:project omzetten naar @.lower:subproject',
            convert_success: '@:project succesvol omgezet',
            convert_error: 'Er is een fout opgetreden',
        },
        en: {
            title: 'Convert @.lower:project to @.lower:subproject',
            convert_success: 'Successfully converted @.lower:project',
            convert_error: 'An error occurred',
        },
    },
});

const notificationStore = useNotificationStore();
const props = defineProps<{ projectId: string; portfolioId: string }>();

const showDialog = defineModel<boolean>({ default: false });

const { mutate: convertProject } = useMutation(
    graphql(`
        mutation ConvertProject($input: ConvertProjectInput!) {
            convertProject(input: $input) {
                ok
            }
        }
    `),
    {
        refetchQueries: ['ProjectDetails', 'Project'],
        awaitRefetchQueries: true,
    }
);

async function onSubmit(values: ProjectConvertFormValues) {
    try {
        await convertProject({
            input: {
                parentProjectId: values.parentProject,
                projectId: props.projectId,
            },
        });

        showDialog.value = false;

        notificationStore.add({
            type: 'success',
            message: t('convert_success'),
        });
    } catch {
        notificationStore.add({
            type: 'error',
            message: t('convert_error'),
        });
    }
}
</script>

<template>
    <AppModal
        v-model="showDialog"
        :title="t('title')"
        :width="744"
        height="auto"
    >
        <template #activator="{ props: activatorProps }">
            <slot name="activator" :props="activatorProps" />
        </template>
        <v-card-text>
            <ProjectConvertForm
                :project-id
                :portfolio-id
                @submit="onSubmit"
                @cancel="showDialog = false"
            />
        </v-card-text>
    </AppModal>
</template>
