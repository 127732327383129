<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import { useMutation } from '@vue/apollo-composable';
import { useNotificationStore } from '@/stores/notification';
import { graphql } from '@/gql';
import { watchEffect } from 'vue';

const props = defineProps<{ deliverableId: string }>();
const emit = defineEmits<{ success: []; loading: [value: boolean] }>();

const { t } = useI18n({
    messages: {
        nl: {
            delete: 'Verwijderen',
            delete_title: 'Permanent verwijderen',
            delete_confirmation:
                'Weet je zeker dat je deze @.lower:deliverable wilt verwijderen?',
            delete_success: '@:deliverable succesvol verwijderd',
        },
        en: {
            delete: 'Delete',
            delete_title: 'Permanently delete',
            delete_confirmation:
                'Are you sure you want to delete this @.lower:deliverable?',
            delete_success: '@:deliverable successfully deleted',
        },
    },
});

const notificationStore = useNotificationStore();

const { mutate: deleteDeliverable, loading: deleteDeliverableLoading } =
    useMutation(
        graphql(`
            mutation DeleteDeliverable($input: DeleteDeliverableInput!) {
                deleteDeliverable(input: $input) {
                    ok
                }
            }
        `),
        {
            refetchQueries: ['ProjectPhases'],
            awaitRefetchQueries: true,
        }
    );

watchEffect(() => {
    emit('loading', deleteDeliverableLoading.value);
});

async function handleDelete() {
    try {
        await deleteDeliverable({
            input: {
                deliverableId: props.deliverableId,
            },
        });

        emit('success');
        notificationStore.add({
            type: 'success',
            message: t('delete_success'),
        });
    } catch {
        notificationStore.add({ type: 'error', message: t('delete_error') });
    }
}
</script>

<template>
    <ConfirmationDialog
        :title="t('delete_title')"
        :confirm-button-text="t('delete')"
        persistent
        :confirm-required="true"
        destructive
        @confirm="handleDelete"
    >
        <template #activator="{ props: activatorProps }">
            <slot
                name="activator"
                :props="activatorProps"
                :loading="deleteDeliverableLoading"
            />
        </template>
    </ConfirmationDialog>
</template>
