import { useRouteQuery } from '@vueuse/router';
import { computed, ref, toValue, type MaybeRefOrGetter } from 'vue';

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 8;
export const SELECTABLE_PAGE_SIZES = [8, 15, 25];

export function usePagination({
    page,
    pageSize,
    disableRouteQuery,
}: {
    page?: MaybeRefOrGetter<number>;
    pageSize?: MaybeRefOrGetter<number>;
    disableRouteQuery?: MaybeRefOrGetter<boolean>;
} = {}) {
    const options = { transform: Number };
    const _page = disableRouteQuery
        ? ref(toValue(page) ?? DEFAULT_PAGE)
        : useRouteQuery('page', page ?? DEFAULT_PAGE, options);
    const _pageSize = disableRouteQuery
        ? ref(toValue(page) ?? DEFAULT_PAGE_SIZE)
        : useRouteQuery('pageSize', pageSize ?? DEFAULT_PAGE_SIZE, options);

    const pagination = computed(() => ({
        number: _page.value,
        size: _pageSize.value,
    }));

    function handlePageReset(loading: boolean, items: unknown[]) {
        if (!loading && items.length === 0) {
            _page.value = DEFAULT_PAGE;
        }
    }

    return { page: _page, pageSize: _pageSize, pagination, handlePageReset };
}
