<script lang="ts" setup>
import { graphql } from '@/gql';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';
import { useRouteParams } from '@vueuse/router';
import {
    VContainer,
    VRow,
    VCol,
    VBtn,
    VCard,
    VSpacer,
    VIcon,
    VList,
    VListItem,
    VListItemTitle,
    VListItemSubtitle,
    VProgressLinear,
    VMenu,
} from 'vuetify/components';
import { RouterLink } from 'vue-router';
import { RouteName } from '@/router';
import { useI18n } from 'vue-i18n';
import { PolicyAction } from '@/types';
import { usePermissions } from '@/composables/permissions';
import ProjectDeleteDialog from './ProjectDeleteDialog.vue';
import SubprojectCreateModal from './SubprojectCreateModal.vue';
import { useUserSettingsStore } from '@/stores/user-settings';

const { hasPermission } = usePermissions();

const { t } = useI18n({
    inheritLocale: true,
    messages: {
        nl: {
            create: '@:subproject aanmaken',
            error: 'Er ging iets mis bij het laden van de @.lower:subprojects',
            no_data: 'Geen @.lower:subprojects beschikbaar',
        },
        en: {
            create: 'Create @.lower:subproject',
            error: 'Failed to load @.lower:subprojects',
            no_data: 'No @.lower:subprojects available',
        },
    },
});

const id = useRouteParams('id');

const userSettingsStore = useUserSettingsStore();
const activePortfolioId = computed(() => userSettingsStore.activePortfolio?.id);

const variables = computed(() => ({
    filters: [
        {
            field: 'id',
            value: id.value,
        },
    ],
}));

const { result, loading, error } = useQuery(
    graphql(`
        query ProjectSubProjects($filters: [FilterCriterion]) {
            projects(filters: $filters) {
                items {
                    id
                    masterPortfolioId
                    subProjects {
                        name
                        description
                        id
                        finished
                        onHold
                    }
                }
            }
        }
    `),
    variables,
    {
        fetchPolicy: 'cache-first',
    }
);

const project = computed(() => result.value?.projects?.items[0] || {});
const subProjects = computed(() => project?.value?.subProjects || []);
const totalCount = computed(() => subProjects.value.length);

function projectStatus(finished: string | null, onHold: boolean) {
    if (finished) return 'finished';
    if (onHold) return 'on_hold';
    return 'running';
}
</script>

<template>
    <v-container class="pt-6">
        <v-row>
            <v-col md="8" class="d-flex align-center">
                <h2 class="text-primary-40 text-title-large">
                    <VIcon
                        icon="$subproject"
                        color="primary-20"
                        size="1.75rem"
                        class="mr-1"
                    />
                    {{ t('subprojects') }}
                </h2>
            </v-col>

            <v-col md="4" class="d-flex justify-end">
                <v-spacer />
                <SubprojectCreateModal
                    v-if="
                        hasPermission(
                            PolicyAction.PROJECT_WRITE,
                            project?.id,
                            project?.masterPortfolio?.id
                        )
                    "
                    :portfolio-id="activePortfolioId || ''"
                    :parent-project-id="String(id)"
                >
                    <template #activator="{ props }">
                        <v-btn
                            :disabled="loading"
                            v-bind="props"
                            variant="outlined"
                            prepend-icon="$add"
                        >
                            {{ t('create') }}
                        </v-btn>
                    </template>
                </SubprojectCreateModal>
            </v-col>
        </v-row>
    </v-container>
    <v-container>
        <v-row>
            <v-col md="12">
                <v-card>
                    <v-list
                        v-if="!loading && totalCount > 0"
                        lines="two"
                        bg-color="transparent"
                        class="py-0"
                    >
                        <v-list-item
                            v-for="(subProject, index) in subProjects"
                            :key="subProject.id"
                            :class="{ 'border-t-thin': index > 0 }"
                        >
                            <v-list-item-title color="primary-95">
                                <router-link
                                    variant="text"
                                    :to="{
                                        name: RouteName['dashboard.project'],
                                        params: { id: subProject.id },
                                    }"
                                    class="text-primary text-decoration-none font-weight-bold"
                                >
                                    {{ subProject.name }}
                                </router-link>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ subProject.description }}
                            </v-list-item-subtitle>
                            <template #append>
                                <p class="text-body-small opacity-20 ml-5">
                                    {{
                                        t(
                                            projectStatus(
                                                subProject.finished,
                                                subProject.onHold
                                            )
                                        )
                                    }}
                                </p>
                                <v-menu
                                    v-if="
                                        hasPermission(
                                            PolicyAction.PROJECT_WRITE,
                                            project?.id,
                                            project?.masterPortfolio?.id
                                        )
                                    "
                                >
                                    <template
                                        #activator="{ props: activatorProps }"
                                    >
                                        <v-btn
                                            icon="$more"
                                            variant="text"
                                            class="ml-3"
                                            :style="{
                                                '--v-btn-height': '2.25rem',
                                            }"
                                            v-bind="activatorProps"
                                        ></v-btn>
                                    </template>
                                    <v-list>
                                        <template
                                            v-if="
                                                typeof subProject.id ===
                                                'string'
                                            "
                                        >
                                            <ProjectDeleteDialog
                                                :project-id="subProject.id"
                                            >
                                                <template
                                                    #activator="{ props }"
                                                >
                                                    <v-list-item
                                                        v-bind="props"
                                                        >{{
                                                            t('delete')
                                                        }}</v-list-item
                                                    >
                                                </template>
                                            </ProjectDeleteDialog>
                                        </template>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-list-item>
                    </v-list>
                    <p v-if="!loading && totalCount === 0">
                        {{ t('no_data') }}
                    </p>
                    <p v-if="!loading && error">
                        {{ t('error') }}
                    </p>
                    <v-progress-linear v-if="loading" indeterminate />
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
