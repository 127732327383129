<script setup lang="ts">
import { useField, useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { VForm, VBtn, VSelect } from 'vuetify/components';
import { toTypedSchema } from '@vee-validate/zod';
import { string, object } from 'zod';
import type { NonNullableFields } from '@/utils';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';
import type { Project } from '@/gql/graphql';
import { graphql } from '@/gql';

export interface ProjectConvertFormValues {
    parentProject: string;
}

const props = defineProps<{ projectId: string; portfolioId: string }>();

const emit = defineEmits<{
    submit: [values: NonNullableFields<ProjectConvertFormValues>];
    cancel: [];
}>();

const { t } = useI18n({
    messages: {
        nl: {
            parent_project: 'Bovenliggend @.lower:project',
            portfolio_link_warning:
                'Dit @.lower:project is niet aan andere @.lower:portfolios gekoppeld, dus het kan omgezet worden. | Dit @.lower:project is gekoppeld aan één @.lower:portfolio. Als je er een @.lower:subproject project van maakt, dan verdwijnt het @.lower:project uit het gekoppelde @.lower:portfolio. | Dit @.lower:project is gekoppeld aan {count} @.lower:portfolios. Als je er een @.lower:subproject van maakt, dan verdwijnt het @.lower:project uit alle gekoppelde @.lower:portfolios.',
            select_project:
                'Selecteer het @.lower:project waarvan dit een @.lower:subproject moet worden.',
            label: {
                parent_project: 'Bovenliggend @.lower:project',
            },
            save: 'Opslaan',
        },
        en: {
            parent_project: 'Parent @.lower:project',
            portfolio_link_warning:
                'This @.lower:project is not linked to other @.lower:portfolios, so it can be converted. | This @.lower:project is linked to a portfolio. After conversion to a @.lower:subproject it will be unlinked from the linked portfolio. | This @.lower:project is linked to {count} @.lower:portfolios. After conversion to a @.lower:subproject it will be unlinked from all linked @.lower:portfolios.',
            select_project:
                'Select the @.lower:project of which this @.lower:project must become a @.lower:subproject moet worden.',
            label: {
                parent_project: 'Parent @.lower:project',
            },
            save: 'Save',
        },
    },
});

const validationSchema = object({
    parentProject: string().min(0),
});

const { handleSubmit, meta } = useForm({
    validationSchema: toTypedSchema(validationSchema),
});

const parentProject = useField<string>('parentProject');

const submit = handleSubmit((values) => {
    emit('submit', values as NonNullableFields<ProjectConvertFormValues>);
});

const variables = computed(() => ({
    filters: [
        {
            field: 'id',
            value: props.projectId,
        },
    ],
}));

const { result: projectPortfolios, loading: projectPortfoliosLoading } =
    useQuery(
        graphql(`
            query ProjectConvertPortfolios($filters: [FilterCriterion]) {
                projects(filters: $filters) {
                    items {
                        portfolios {
                            id
                        }
                    }
                }
            }
        `),
        variables
    );

const project = computed(() => projectPortfolios?.value?.projects.items[0]);
const numberOfPortfolios = computed(() =>
    project.value?.portfolios.length ? project.value?.portfolios.length - 1 : 0
);

const portfolioQueryVariables = computed(() => ({
    portfolioId: props.portfolioId,
}));

const { result: portfolioProjectsResult, loading: portfolioProjectsLoading } =
    useQuery(
        graphql(`
            query ProjectConvertProjects($portfolioId: ID!) {
                portfolio(portfolioId: $portfolioId) {
                    projects {
                        name
                        id
                        parentProjectId
                    }
                }
            }
        `),
        portfolioQueryVariables
    );

const loading = computed(
    () => projectPortfoliosLoading || portfolioProjectsLoading
);

const items = computed(() =>
    (portfolioProjectsResult?.value?.portfolio.projects || []).filter(
        (p: Partial<Project>) =>
            p.parentProjectId === null && p.id !== props.projectId
    )
);
</script>

<template>
    <v-form
        class="d-flex flex-column ga-3"
        :disabled="loading"
        @submit.prevent="submit"
    >
        <p class="text-body-2">
            {{ t('portfolio_link_warning', numberOfPortfolios) }}
        </p>
        <p class="text-body-2 mb-3">{{ t('select_project') }}</p>
        <v-select
            v-model="parentProject.value.value"
            name="masterPortfolioId"
            :label="t('label.parent_project')"
            :placeholder="t('label.parent_project')"
            :items
            :disabled="portfolioProjectsLoading"
            item-title="name"
            item-value="id"
        />
        <div class="d-flex justify-end ga-3 mt-5">
            <v-btn variant="text" @click="$emit('cancel')">
                {{ t('cancel') }}
            </v-btn>
            <v-btn
                type="submit"
                variant="elevated"
                :disabled="!meta.valid || !meta.dirty"
            >
                {{ t('save') }}
            </v-btn>
        </div>
    </v-form>
</template>
