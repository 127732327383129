import { portfoliosToNodes } from './portfoliosToNodes';
import type { Elements, Edge, Node } from '@vue-flow/core';
import { isNode, isEdge, getIncomers } from '@vue-flow/core';
import type { InjectionKey } from 'vue';
import { ref } from 'vue';

export { portfoliosToNodes };

const test = ref<string[]>([]);
export const STARRED_PORTFOLIOS: InjectionKey<typeof test> =
    Symbol('starredPortfolios');

export function getIncomersRecursive(
    startingNode: Node,
    elements: Elements,
    visitedNodes: Set<string> = new Set()
): Elements {
    if (visitedNodes.has(startingNode.id)) return [];
    visitedNodes.add(startingNode.id);

    const edges = elements.filter((el) => isEdge(el)) as Edge[];
    const nodes = elements.filter((el) => isNode(el)) as Node[];

    const incomingElements = getIncomers(startingNode.id, nodes, edges);
    const connectedNodes: Elements = incomingElements.flatMap((element) => {
        return getIncomersRecursive(element, elements, visitedNodes);
    });

    return [startingNode, ...connectedNodes];
}

export function highlightPath(node: Node, elements: Elements) {
    elements.forEach((element) => {
        element.class = element.class + ' hide';
    });
    const incomers = getIncomersRecursive(node, elements);
    incomers.forEach((incomer) => {
        // find the right edges
        const edges = elements.filter(
            (element) => element.type === 'edge'
        ) as Edge[];
        const highlitEdges = edges.filter((edge) => edge.target === incomer.id);
        highlitEdges.forEach((edge) => (edge.class = ''));
        if (isEdge(incomer) || isNode(incomer)) {
            if (typeof incomer.class === 'string') {
                incomer.class = '';
            }
        }
    });
}

export function clearHighlightPath(elements: Elements) {
    elements.forEach((element) => {
        if (typeof element.class === 'string') {
            element.class = '';
        }
    });
}
