<script setup lang="ts">
import { useField, useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import {
    VForm,
    VTextField,
    VRow,
    VCol,
    VBtn,
    VSelect,
} from 'vuetify/components';
import { toTypedSchema } from '@vee-validate/zod';
import { requirements } from '@/utils/formRequirements';
import { string, object } from 'zod';
import type { CreateProjectInput } from '@/gql/graphql';

export type SubprojectFormValues = Pick<
    CreateProjectInput,
    'abbreviation' | 'name' | 'status'
>;

const props = defineProps<{
    initialValues?: (SubprojectFormValues & { id?: string }) | null;
    disabled?: boolean;
    loading?: boolean;
}>();

const emit = defineEmits<{
    submit: [values: SubprojectFormValues];
    cancel: [];
}>();

const { t } = useI18n();

const { project } = requirements;
const validationSchema = object({
    name: string()
        .min(project.name.min, t('error.required_field'))
        .max(
            project.name.max,
            t('error.maximum_characters', { max: project.name.max })
        ),
    abbreviation: string()
        .min(project.abbreviation.min, t('error.required_field'))
        .max(
            project.abbreviation.max,
            t('error.maximum_characters', { max: project.abbreviation.max })
        ),
});

const { handleSubmit, meta } = useForm({
    validationSchema: toTypedSchema(validationSchema),
    initialValues: props.initialValues
        ? props.initialValues
        : {
              status: 'project',
              name: '',
              abbreviation: '',
          },
});

const status = useField<string>('status');
const name = useField<string | null>('name');
const abbreviation = useField<string | null>('abbreviation');

const submit = handleSubmit((values) => {
    emit('submit', values);
});

const statusOptions: {
    value: 'idea' | 'project';
    title: string;
}[] = [
    {
        title: t('idea'),
        value: 'idea',
    },
    {
        title: t('project'),
        value: 'project',
    },
];
</script>

<template>
    <v-form class="d-flex flex-column ga-3" :disabled @submit.prevent="submit">
        <v-row>
            <v-col sm="6">
                <v-select
                    v-model="status.value.value"
                    name="status"
                    :items="statusOptions"
                    :label="t('status')"
                ></v-select>
            </v-col>
            <v-col sm="6">
                <v-text-field
                    v-model="abbreviation.value.value"
                    :label="t('abbreviation')"
                    :placeholder="t('abbreviation')"
                    :counter="project.abbreviation.max"
                    :maxlength="project.abbreviation.max"
                    :error-messages="abbreviation.errorMessage.value"
                    :clearable="false"
                    name="abbreviation"
                    autofocus
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-text-field
                    v-model="name.value.value"
                    :placeholder="t('name')"
                    :error-messages="name.errorMessage.value"
                    name="name"
                    :counter="project.name.max"
                    :maxlength="project.name.max"
                    :label="t('name')"
                ></v-text-field>
            </v-col>
        </v-row>
        <div class="d-flex justify-end ga-3 mt-5">
            <v-btn variant="text" @click="$emit('cancel')">
                {{ t('cancel') }}
            </v-btn>
            <v-btn
                type="submit"
                variant="elevated"
                :disabled="!meta.valid || !meta.dirty"
                :loading
            >
                {{ t('save') }}
            </v-btn>
        </div>
    </v-form>
</template>
