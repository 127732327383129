<script lang="ts" setup>
import { type TagValue, type UpdateProjectInput } from '@/gql/graphql';
import { ProjectStatus2 } from '@/types';
import { isNotNull } from '@/utils/type';
import { graphql } from '@/gql';
import { computed, ref } from 'vue';
import { RouterView } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { useUserSettingsStore } from '@/stores/user-settings';
import ProjectStatusMenu from '@/components/ProjectStatusMenu.vue';
import AppViewHeader from '@/components/AppViewHeader.vue';
import {
    VContainer,
    VRow,
    VProgressLinear,
    VCol,
    VChipGroup,
    VTabs,
    VTab,
    VBtn,
    VIcon,
} from 'vuetify/components';
import { RouteName } from '@/router';
import ProjectEditForm from '@/components/ProjectEditForm.vue';
import { useNotificationStore } from '@/stores/notification';
import { useRouteParams } from '@vueuse/router';

type ProjectTab =
    | 'description'
    | 'deliverables'
    | 'subprojects'
    | 'highlights'
    | 'documents';

type TabRoute = {
    name: ProjectTab;
    route: RouteName;
};

const isSubproject = computed(() => Boolean(project.value?.parentProjectId));

const tabs = computed(() => {
    const tabRoutes: TabRoute[] = [
        {
            name: 'description',
            route: RouteName['dashboard.project'],
        },
        {
            name: 'deliverables',
            route: RouteName['dashboard.project.deliverables'],
        },
        {
            name: 'highlights',
            route: RouteName['dashboard.project.highlights'],
        },
        {
            name: 'documents',
            route: RouteName['dashboard.project.downloads'],
        },
    ];

    if (!isSubproject.value) {
        tabRoutes.splice(2, 0, {
            name: 'subprojects',
            route: RouteName['dashboard.project.subprojects'],
        });
    }

    return tabRoutes;
});
const { t } = useI18n({
    inheritLocale: true,
    messages: {
        nl: {
            highlights: 'Highlights & @:previews',
            downloads: '@:documents',
            update_success: '@:project successvol bijgewerkt',
            current_portfolio: 'Huidig @.lower:portfolio',
        },
        en: {
            highlights: 'Highlights & @:previews',
            downloads: '@:documents',
            update_success: '@:project successfully updated',
            current_portfolio: 'Current @.lower:portfolio',
        },
    },
});

const projectId = useRouteParams<string>('id');
const userSettingsStore = useUserSettingsStore();
const notificationStore = useNotificationStore();
const userSettings = useUserSettingsStore();

const projectVariables = computed(() => ({
    filters: [
        {
            field: 'id',
            value: projectId.value,
        },
    ],
}));

const { result, loading: loadingProject } = useQuery(
    graphql(`
        query Project($filters: [FilterCriterion]) {
            projects(filters: $filters) {
                items {
                    id
                    name
                    status
                    onHold
                    finished
                    tagValues {
                        name
                        id
                        portfolioId
                    }
                    masterPortfolio {
                        id
                    }
                    parentProjectId
                }
            }
        }
    `),
    projectVariables
);

const activePortfolioId = computed(
    () => userSettings.activePortfolio?.id ?? ''
);

const projectDetailsVariables = computed(() => ({
    projectId: projectId.value,
    portfolioId: activePortfolioId.value,
}));

const { result: projectDetailsResult, loading: loadingProjectDetails } =
    useQuery(
        graphql(`
            query ProjectDetails($projectId: ID!, $portfolioId: ID!) {
                project(projectId: $projectId, portfolioId: $portfolioId)
            }
        `),
        projectDetailsVariables,
        { fetchPolicy: 'cache-first' }
    );

const project = computed(() => result.value?.projects?.items[0]);
const tags = computed(
    () =>
        project.value?.tagValues
            .filter(isNotNull)
            .filter(
                (t: TagValue) => t.portfolioId === activePortfolioId.value
            ) ?? []
);
const status = computed(() => project.value?.status);
const projectDetails = computed(() => projectDetailsResult.value?.project);
const focusId = computed(() => projectDetails.value?.focus?.id || '');
const needId = computed(() => projectDetails.value?.need?.id || '');

const status2 = computed<ProjectStatus2>(() => {
    if (project.value?.finished) return ProjectStatus2.FINISHED;
    else if (project.value?.onHold) return ProjectStatus2.ON_HOLD;
    return ProjectStatus2.ACTIVE;
});

const isSubmitting = ref(false);
const isEditing = ref(false);

const form = ref<InstanceType<typeof ProjectEditForm>>();
const isValid = computed(() => !!form.value?.isValid);

const onSubmit = async (values: UpdateProjectInput) => {
    try {
        await updateProject({ input: values });

        isEditing.value = false;
        notificationStore.add({
            type: 'success',
            message: t('update_success'),
        });
    } catch {
        notificationStore.add({ type: 'error', message: t('update_error') });
    } finally {
        isSubmitting.value = false;
    }
};

const { mutate: updateProject } = useMutation(
    graphql(`
        mutation UpdateProject($input: UpdateProjectInput!) {
            updateProject(input: $input) {
                result
            }
        }
    `),
    {
        refetchQueries: ['Project', 'ProjectDetails'],
        awaitRefetchQueries: true,
    }
);
</script>

<template>
    <AppViewHeader :is-editing :class="{ subproject: isSubproject }">
        <v-container class="pb-6">
            <v-row class="d-flex">
                <v-col sm="12" md="6" class="d-flex flex-column flex-wrap">
                    <v-progress-linear
                        v-if="loadingProject"
                        color="neutral"
                        type="text"
                    ></v-progress-linear>
                    <template v-else-if="project">
                        <h1>
                            <v-icon v-if="isSubproject" icon="$subdirectory" />
                            {{ project.name }}
                        </h1>
                        <div v-if="!isEditing">
                            <span class="text-label-large">
                                {{ t('current_portfolio') }}:
                                {{ userSettingsStore.activePortfolio?.name }}
                            </span>
                            <v-chip-group
                                v-if="project?.tagValues?.length"
                                class="pb-0"
                                column
                            >
                                <v-tag
                                    v-for="tag in tags"
                                    :key="tag.id"
                                    variant="outlined"
                                    >{{ tag.name }}</v-tag
                                >
                            </v-chip-group>
                        </div>
                    </template>
                </v-col>

                <v-col
                    sm="12"
                    md="6"
                    class="d-flex flex-row align-end justify-end ga-3"
                >
                    <ProjectStatusMenu
                        v-if="!isEditing && project?.status"
                        :status
                        :status2
                        :loading="loadingProjectDetails"
                        :project
                        :focus-id
                        :need-id
                        :is-subproject
                        @edit="isEditing = true"
                    />
                    <div v-if="isEditing">
                        <v-btn
                            variant="outlined"
                            class="mr-5"
                            @click="
                                isEditing = false;
                                isSubmitting = false;
                            "
                        >
                            {{ t('cancel') }}
                        </v-btn>
                        <v-btn
                            :disabled="!isValid"
                            @click="isSubmitting = true"
                        >
                            {{ t('save') }}
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </AppViewHeader>
    <v-tabs v-if="!isEditing">
        <v-container>
            <v-tab
                v-for="tab in tabs"
                :key="tab.name"
                :to="{ name: tab.route, params: { id: project?.id } }"
                hide-slider
            >
                {{ t(tab.name) }}
            </v-tab>
        </v-container>
    </v-tabs>
    <div class="project-content">
        <router-view v-if="!isEditing" />
        <v-container v-else class="mt-12">
            <ProjectEditForm ref="form" :is-submitting @submit="onSubmit" />
        </v-container>
    </div>
</template>

<style lang="scss" scoped>
.project-content {
    background-color: rgb(var(--v-theme-neutral-99));
    padding-bottom: 1.5rem;
}

.subproject {
    border-top: 1px solid white;
    border-top-style: dashed;
}
</style>
