<script lang="ts" setup>
import ProjectDescriptionCard from './ProjectDescriptionCard.vue';
import { VList, VListItem } from 'vuetify/components';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { format, parseISO } from 'date-fns';

const { t } = useI18n({
    messages: {
        nl: {
            title: 'Periode',
            label: 'Begin- en einddatum',
            until: 't/m',
            n_a: 'n.v.t.',
        },

        en: {
            title: 'Period',
            label: 'Start and end date',
            until: 'until',
            n_a: 'n/a',
        },
    },
});

const props = defineProps<{
    startDate?: string;
    endDate?: string;
    loading: boolean;
}>();

const formattedStartDate = computed(() => {
    if (!props.startDate) return '';
    return format(parseISO(props.startDate!), 'dd-MM-yyyy');
});

const formattedEndDate = computed(() => {
    if (!props.endDate) return '';
    return format(parseISO(props.endDate!), 'dd-MM-yyyy');
});

const formattedPeriod = computed(() => {
    return `${formattedStartDate.value || t('n_a')} ${t('until')} ${formattedEndDate.value || t('n_a')}`;
});
</script>

<template>
    <ProjectDescriptionCard :title="t('title')" icon="event_note" :loading>
        <v-list bg-color="transparent">
            <v-list-item :title="t('label')" :subtitle="formattedPeriod" />
        </v-list>
    </ProjectDescriptionCard>
</template>
