<script setup lang="ts">
import { useField, useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import {
    VForm,
    VTextField,
    VTextarea,
    VCheckbox,
    VSelect,
    VBtn,
} from 'vuetify/components';
import { VDateInput } from 'vuetify/labs/VDateInput';
import { toTypedSchema } from '@vee-validate/zod';
import { requirements } from '@/utils/formRequirements';
import { string, date, object, boolean, literal } from 'zod';
import { useQuery } from '@vue/apollo-composable';
import { graphql } from '@/gql';
import { computed } from 'vue';
import type { Phase } from '@/gql/graphql';
import { useDateTime } from '@/composables/datetime';

export interface DeliverableFormValues {
    name: string;
    description: string;
    milestone: boolean;
    endDate: string;
    phaseId?: string | null;
}

const props = defineProps<{
    projectId: string;
    initialValues?: DeliverableFormValues;
    disabled?: boolean;
    loading?: boolean;
}>();

const emit = defineEmits<{
    submit: [values: DeliverableFormValues];
    cancel: [];
}>();

const { t } = useI18n({
    messages: {
        nl: {
            milestone: 'Milestone',
            general: 'Algemeen',
            value_label: 'Vul waarden in',
            no_phase: 'Geen fase',
        },
        en: {
            milestone: 'Milestone',
            general: 'General',
            value_label: 'Fill in values',
            no_phase: 'No phase',
        },
    },
});

const { deliverable } = requirements;

const hasLegacyCharacterLimits = computed(() => {
    const nameLength = props?.initialValues?.name?.length || 0;
    return nameLength > deliverable.name.max;
});

const validationSchema = object({
    name: string()
        .min(deliverable.name.min, t('error.required_field'))
        .max(
            hasLegacyCharacterLimits.value
                ? props?.initialValues?.name?.length ?? 100
                : deliverable.name.max,
            t('error.maximum_characters', { max: deliverable.name.max })
        ),
    description: string()
        .min(deliverable.description.min, t('error.required_field'))
        .max(
            deliverable.description.max,
            t('error.maximum_characters', { max: deliverable.description.max })
        ),
    milestone: boolean().default(false),
    endDate: date({ required_error: t('error.required_field') }).or(
        literal(null).refine(() => false, t('error.required_field'))
    ),
    phase: string().nullable(),
});

const { handleSubmit, meta } = useForm({
    validationSchema: toTypedSchema(validationSchema),
    initialValues: props.initialValues
        ? {
              ...props.initialValues,
              endDate: new Date(props.initialValues.endDate),
              phase: props.initialValues.phaseId ?? null,
          }
        : {
              name: '',
              description: '',
              milestone: false,
              phase: null,
              endDate: null,
          },
});

const name = useField<string>('name');
const description = useField<string>('description');
const endDate = useField<Date | null>('endDate');
const milestone = useField<boolean>('milestone');
const phase = useField<string>('phase');

const submit = handleSubmit((values) => {
    const { formattedDate } = useDateTime(values.endDate);
    if (!formattedDate.value) return;

    emit('submit', {
        name: values.name,
        description: values.description,
        milestone: values.milestone,
        endDate: formattedDate.value,
        phaseId: values.phase,
    });
});

const variables = computed(() => ({
    filters: [
        {
            field: 'id',
            value: props.projectId,
        },
    ],
}));

const { result, loading: isLoadingPhases } = useQuery(
    graphql(`
        query Phases($filters: [FilterCriterion]) {
            projects(filters: $filters) {
                items {
                    phases {
                        name
                        id
                    }
                }
            }
        }
    `),
    variables
);

const items = computed(() => result.value?.projects?.items ?? []);
const project = computed(() => items.value[0] || {});

const phasesFromProject = computed<{ value: string; title: string }[]>(() => {
    if (!project.value) return [];

    return (
        project.value.phases?.map((phase: Pick<Phase, 'name' | 'id'>) => ({
            title: phase.name,
            value: phase.id,
        })) || []
    );
});

const phaseOptions = computed<{ value: string | null; title: string }[]>(() => [
    {
        title: t('no_phase'),
        value: null,
    },
    ...phasesFromProject.value,
]);
</script>

<template>
    <v-form class="d-flex flex-column ga-3" :disabled @submit.prevent="submit">
        <v-text-field
            v-model="name.value.value"
            :label="t('name')"
            :error-messages="name.errorMessage.value"
            :clearable="false"
            autofocus
            @click:clear="name.value.value = ''"
        />

        <v-textarea
            v-model="description.value.value"
            :label="t('description')"
            :error-messages="description.errorMessage.value"
            persistent-placeholder
            @click:clear="description.value.value = ''"
        />

        <v-date-input
            v-model="endDate.value.value"
            :label="t('end_date')"
            :error-messages="endDate.errorMessage.value"
            :disabled
            variant="outlined"
            prepend-inner-icon="$date"
            prepend-icon=""
            hide-actions
            clearable
            readonly
            placeholder="dd-mm-yyyy"
            @click:clear="endDate.setValue(null)"
        />

        <v-checkbox
            v-model="milestone.value.value"
            class="my-n2"
            color="primary"
            :label="t('milestone')"
        />

        <v-select
            v-model="phase.value.value"
            :items="phaseOptions"
            :loading="isLoadingPhases"
            :error-messages="phase.errorMessage.value"
        />

        <div class="d-flex justify-end ga-3 mt-5">
            <v-btn variant="text" @click="emit('cancel')">
                {{ t('cancel') }}
            </v-btn>
            <v-btn
                type="submit"
                variant="elevated"
                :disabled="!meta.valid || !meta.dirty"
                :loading
            >
                {{ t('save') }}
            </v-btn>
        </div>
    </v-form>
</template>
