<script lang="ts" setup>
import { graphql } from '@/gql';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';
import {
    VRow,
    VCol,
    VBtn,
    VCard,
    VSpacer,
    VIcon,
    VList,
    VListItem,
    VListItemTitle,
    VListItemSubtitle,
    VProgressLinear,
    VMenu,
} from 'vuetify/components';
import { useI18n } from 'vue-i18n';
import { PolicyAction } from '@/types';
import { usePermissions } from '@/composables/permissions';
import PreviewDeleteDialog from '@/components/PreviewDeleteDialog.vue';
import PreviewCreateModal from '@/components/PreviewCreateModal.vue';
import PreviewEditModal from '@/components/PreviewEditModal.vue';
import { format, parseISO } from 'date-fns';
import { useUserSettingsStore } from '@/stores/user-settings';

const { hasPermission } = usePermissions();

const { projectId } = defineProps<{
    projectId: string;
}>();

const userSettingsStore = useUserSettingsStore();
const activePortfolioId = computed(() => userSettingsStore.activePortfolio?.id);

const { t } = useI18n({
    inheritLocale: true,
    messages: {
        nl: {
            create: '@:preview aanmaken',
            error: 'Er ging iets mis bij het laden van de @.lower:previews',
            no_data: 'Geen @.lower:previews beschikbaar',
        },
        en: {
            create: 'Create @.lower:preview',
            error: 'Failed to load @.lower:previews',
            no_data: 'No @.lower:previews available',
        },
    },
});

const variables = computed(() => ({
    filters: [
        {
            field: 'id',
            value: projectId,
        },
    ],
}));

const { result, loading, error } = useQuery(
    graphql(`
        query ProjectPreviews($filters: [FilterCriterion]) {
            projects(filters: $filters) {
                items {
                    previews {
                        id
                        name
                        description
                        date
                    }
                }
            }
        }
    `),
    variables,
    { fetchPolicy: 'no-cache' }
);

const project = computed(() => result.value?.projects?.items[0] || {});
const previews = computed(() => project?.value?.previews || []);
</script>

<template>
    <v-row>
        <v-col md="6" class="d-flex align-center">
            <h2 class="text-primary-40 text-title-large">
                <VIcon
                    icon="$preview"
                    color="primary-20"
                    size="1.75rem"
                    class="mr-1"
                />
                {{ t('previews') }}
            </h2>
        </v-col>

        <v-col md="6" class="d-flex justify-end">
            <v-spacer />
            <PreviewCreateModal
                v-if="
                    hasPermission(
                        PolicyAction.PROJECT_WRITE,
                        projectId,
                        activePortfolioId
                    )
                "
                :project-id="String(projectId)"
            >
                <template #activator="{ props }">
                    <v-btn
                        :disabled="loading"
                        v-bind="props"
                        variant="outlined"
                        prepend-icon="$add"
                    >
                        {{ t('create') }}
                    </v-btn>
                </template>
            </PreviewCreateModal>
        </v-col>
    </v-row>
    <v-row>
        <v-col md="12">
            <v-card>
                <v-list
                    v-if="!loading"
                    lines="two"
                    bg-color="transparent"
                    class="py-0"
                >
                    <v-list-item
                        v-for="(preview, index) in previews"
                        :key="preview.id"
                        :class="{ 'border-t-thin': index > 0 }"
                        class="justify-start"
                    >
                        <v-list-item-title
                            color="primary-95"
                            class="text-primary font-weight-bold overflow-y-visible text-wrap mb-1"
                        >
                            {{ preview.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ preview.description }}
                        </v-list-item-subtitle>
                        <template #append>
                            <p class="text-body-small opacity-50 ml-5">
                                {{
                                    format(parseISO(preview.date), 'dd-MM-yyyy')
                                }}
                            </p>
                            <v-menu
                                v-if="
                                    hasPermission(
                                        PolicyAction.PROJECT_WRITE,
                                        projectId
                                    )
                                "
                            >
                                <template
                                    #activator="{ props: activatorProps }"
                                >
                                    <v-btn
                                        icon="$more"
                                        variant="text"
                                        class="ml-3 px-0"
                                        :style="{ '--v-btn-height': '1rem' }"
                                        v-bind="activatorProps"
                                    ></v-btn>
                                </template>
                                <v-list>
                                    <PreviewEditModal :preview>
                                        <template #activator="{ props }">
                                            <v-list-item v-bind="props">{{
                                                t('edit')
                                            }}</v-list-item>
                                        </template>
                                    </PreviewEditModal>
                                    <PreviewDeleteDialog
                                        :preview-id="preview.id"
                                    >
                                        <template #activator="{ props }">
                                            <v-list-item v-bind="props">{{
                                                t('delete')
                                            }}</v-list-item>
                                        </template>
                                    </PreviewDeleteDialog>
                                </v-list>
                            </v-menu>
                        </template>
                    </v-list-item>
                </v-list>
                <p v-if="!loading && !previews.length">
                    {{ t('no_data') }}
                </p>
                <p v-if="!loading && error">
                    {{ t('error') }}
                </p>
                <v-progress-linear v-if="loading" indeterminate />
            </v-card>
        </v-col>
    </v-row>
</template>

<style lang="scss" scoped>
.v-list-item-title {
    line-height: 1.1;
}

.v-list-item--two-line {
    :deep(.v-list-item-subtitle) {
        line-clamp: revert;
        -webkit-line-clamp: revert;
    }
}

:deep(.v-list-item__append) {
    align-self: start;
}
</style>
