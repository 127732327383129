<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import SubprojectModal from './SubprojectModal.vue';
import SubprojectForm, {
    type SubprojectFormValues,
} from './SubprojectForm.vue';
import { useMutation } from '@vue/apollo-composable';
import { graphql } from '@/gql';
import { useNotificationStore } from '@/stores/notification';

const showDialog = defineModel<boolean>({ default: false });
const { portfolioId, parentProjectId } = defineProps<{
    portfolioId: string;
    parentProjectId: string;
}>();

const { t } = useI18n({
    messages: {
        nl: {
            title: '@:subproject aanmaken',
            create_success: '@:subproject succesvol aangemaakt',
        },
        en: {
            title: 'Create @.lower:subproject',
            create_success: '@:subproject successfully created',
        },
    },
});

const notificationStore = useNotificationStore();

const { mutate: createProject, loading: isCreating } = useMutation(
    graphql(`
        mutation CreateSubProject($input: CreateProjectInput!) {
            createProject(input: $input) {
                project {
                    id
                }
            }
        }
    `),
    {
        refetchQueries: ['ProjectSubProjects'],
        awaitRefetchQueries: true,
    }
);

async function handleCreate(values: SubprojectFormValues) {
    try {
        await createProject({
            input: { portfolioId, parentProjectId, ...values },
        });

        showDialog.value = false;
        notificationStore.add({
            type: 'success',
            message: t('create_success'),
        });
    } catch {
        notificationStore.add({ type: 'error', message: t('create_error') });
    }
}
</script>

<template>
    <SubprojectModal v-model="showDialog" :title="t('title')">
        <template #activator="{ props: activatorProps }">
            <slot name="activator" :props="activatorProps" />
        </template>
        <SubprojectForm
            :disabled="isCreating"
            :loading="isCreating"
            @cancel="showDialog = false"
            @submit="handleCreate"
        />
    </SubprojectModal>
</template>
