<script setup lang="ts">
import DeliverableModal from './DeliverableModal.vue';
import DeliverableForm, {
    type DeliverableFormValues,
} from './DeliverableForm.vue';

import { useI18n } from 'vue-i18n';
import { useMutation } from '@vue/apollo-composable';
import { graphql } from '@/gql';
import { useNotificationStore } from '@/stores/notification';
import { useRouteParams } from '@vueuse/router';

const notificationStore = useNotificationStore();

const showDialog = defineModel<boolean>({ default: false });

const { t } = useI18n({
    messages: {
        nl: {
            title: '@:deliverable aanmaken',
            create_success: '@:deliverable succesvol toegevoegd',
        },
        en: {
            title: 'Create @.lower:deliverable',
            create_success: '@:deliverable successfully added',
        },
    },
});

const projectId = useRouteParams<string>('id');

const { mutate: createDeliverable, loading: isCreating } = useMutation(
    graphql(`
        mutation CreateDeliverable($input: CreateDeliverableInput!) {
            createDeliverable(input: $input) {
                deliverable {
                    id
                }
            }
        }
    `),
    {
        refetchQueries: ['ProjectPhases'],
        awaitRefetchQueries: true,
    }
);

async function onSubmit(values: DeliverableFormValues) {
    try {
        await createDeliverable({
            input: {
                name: values.name,
                description: values.description,
                milestone: values.milestone,
                endDate: values.endDate,
                phaseId: values.phaseId,
                projectId: projectId.value,
            },
        });
        showDialog.value = false;
        notificationStore.add({
            type: 'success',
            message: t('create_success'),
        });
    } catch {
        notificationStore.add({
            type: 'error',
            message: t('create_error'),
        });
    }
}
</script>

<template>
    <DeliverableModal v-model="showDialog" :title="t('title')">
        <template #activator="{ props: activatorProps }">
            <slot name="activator" :props="activatorProps" />
        </template>
        <DeliverableForm
            :disabled="isCreating"
            :loading="isCreating"
            :project-id
            @submit="onSubmit"
            @cancel="showDialog = false"
        />
    </DeliverableModal>
</template>
