<script lang="ts" setup>
import type { DeliverableStatus, DeliverableState } from '@/gql/graphql';
import { VTooltip } from 'vuetify/components';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

interface DeliverableTypeSymbolProps {
    milestone?: boolean;
    status: DeliverableStatus;
    state: DeliverableState;
}

enum DeliverableStatusColor {
    'finished' = 'success',
    'pending' = 'neutral-50',
    'bottleneck' = 'warning',
    'escalation' = 'error-40',
}

const { t } = useI18n({
    messages: {
        nl: {
            milestone: 'Milestone',
            finished: 'Afgerond',
            pending: 'Pending',
            bottleneck: 'Bottleneck',
            escalation: 'Escalatie',
        },
        en: {
            milestone: 'Milestone',
            finished: 'Finished',
            pending: 'Pending',
            bottleneck: 'Bottleneck',
            escalation: 'Escalation',
        },
    },
});

const {
    milestone = false,
    status,
    state,
} = defineProps<DeliverableTypeSymbolProps>();

const actualStatus = computed(() => {
    if (state === 'escalation') {
        return 'escalation';
    }

    if (state === 'bottleneck') {
        return 'bottleneck';
    }

    if (status === 'finished') {
        return 'finished';
    }

    if (status === 'pending') {
        return 'pending';
    } else {
        return 'pending';
    }
});

const isMilestone = computed(() => milestone);
const templateClass = computed(() => {
    return `text-${DeliverableStatusColor[actualStatus.value]}` || 'success';
});

const tooltipText = computed(() => {
    const label = t(status);

    if (isMilestone.value) {
        return `${t('milestone')} - ${label}`;
    }

    return label;
});
</script>

<template>
    <v-tooltip :text="tooltipText">
        <template #activator="{ props: tooltipProps }">
            <div
                class="deliverable-status-symbol"
                :class="templateClass"
                v-bind="tooltipProps"
            >
                <svg
                    v-if="isMilestone"
                    width="13"
                    height="18"
                    viewBox="0 0 13 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M6.48535 17.4854L0.25007 9.00007L6.48535 0.514789L12.7206 9.00007L6.48535 17.4854Z"
                        fill="currentColor"
                    />
                </svg>
                <svg
                    v-else
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="6" cy="6" r="6" fill="currentColor" />
                </svg>
            </div>
        </template>
    </v-tooltip>
</template>
