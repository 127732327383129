<script lang="ts" setup>
import ProjectDescriptionCard from './ProjectDescriptionCard.vue';
import { VList, VListItem } from 'vuetify/components';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

const { t } = useI18n({
    messages: {
        nl: {
            sourcePortfolio: '@:master_portfolio',
            title: 'Portfolio Framework',
            no_theme: 'Geen @.lower:theme',
            no_program: 'Geen @.lower:program',
            no_department: 'Geen @.lower:department',
        },
        en: {
            sourcePortfolio: '@:master_portfolio',
            title: 'Portfolio Framework',
            no_theme: 'Geen @.lower:theme',
            no_program: 'Geen @.lower:program',
            no_department: 'Geen @.lower:department',
        },
    },
});

const props = defineProps<{
    sourcePortfolio: string;
    theme?: string;
    program?: string;
    department?: string;
    loading: boolean;
}>();

const items = computed(() => ({
    sourcePortfolio: props.sourcePortfolio,
    theme: props.theme ?? t('no_theme'),
    program: props.program ?? t('no_program'),
    department: props.department ?? t('no_department'),
}));
</script>

<template>
    <ProjectDescriptionCard
        :title="t('title')"
        icon="display_settings"
        :loading
    >
        <v-list>
            <v-list-item
                v-for="[key, value] of Object.entries(items)"
                :key
                :title="t(key)"
                :subtitle="value"
            />
        </v-list>
    </ProjectDescriptionCard>
</template>
