import { type SortingCriterion } from '@/gql/graphql';
import { toDirection } from '@/utils/api';
import { ref } from 'vue';

export function useSorting(defaultSorting: SortingCriterion[] = []) {
    const sorting = ref<SortingCriterion[]>(defaultSorting);

    function handleUpdateSortBy([firstSortBy]: {
        key: string;
        order: 'asc' | 'desc';
    }[]) {
        if (firstSortBy) {
            sorting.value = [
                {
                    field: firstSortBy.key,
                    direction: toDirection(firstSortBy.order),
                },
            ];
        } else {
            sorting.value = defaultSorting;
        }
    }

    return { sorting, handleUpdateSortBy };
}
