<script setup lang="ts">
import { VCardText } from 'vuetify/components';
import AppModal from '@/components/AppModal.vue';
import { useI18n } from 'vue-i18n';
import { useNotificationStore } from '@/stores/notification';
import { useMutation } from '@vue/apollo-composable';
import { graphql } from '@/gql';
import { useUserSettingsStore } from '@/stores/user-settings';
import type { Portfolio } from '@/gql/graphql';
import { useSelectablePortfolios } from '@/composables/portfolios';
import ProjectMoveForm from './ProjectMoveForm.vue';

const { t } = useI18n({
    messages: {
        nl: {
            title: '@:master_portfolio wijzigen',
            move_success: '@:master_portfolio succesvol gewijzigd',
            move_error: 'Er is een fout opgetreden',
        },
        en: {
            title: 'Change @.lower:master_portfolio',
            move_success: 'Successfully changed @.lower:master_portfolio',
            move_error: 'An error occurred',
        },
    },
});

const notificationStore = useNotificationStore();
const userSettingsStore = useUserSettingsStore();

const props = defineProps<{
    projectId: string;
    portfolioId: string;
    focusId: string;
    needId: string;
}>();

const showDialog = defineModel<boolean>({ default: false });

const { portfolios } = useSelectablePortfolios();

const { mutate: moveProject } = useMutation(
    graphql(`
        mutation MoveProject($input: MoveProjectInput!) {
            moveProject(input: $input) {
                result
            }
        }
    `),
    {
        refetchQueries: ['ProjectDetails', 'Project'],
        awaitRefetchQueries: true,
    }
);

async function onSubmit(values: ProjectMoveFormValues) {
    try {
        await moveProject({
            input: {
                ...values,
                projectId: props.projectId,
            },
        });

        userSettingsStore.setActivePortfolio(
            (portfolios.value || []).find(
                (p: Portfolio) => p.id === values.portfolioId
            )
        );

        showDialog.value = false;

        notificationStore.add({
            type: 'success',
            message: t('move_success'),
        });
    } catch {
        notificationStore.add({
            type: 'error',
            message: t('move_error'),
        });
    }
}
</script>

<template>
    <AppModal
        v-model="showDialog"
        :title="t('title')"
        :width="744"
        height="auto"
        persistent
    >
        <template #activator="{ props: activatorProps }">
            <slot name="activator" :props="activatorProps" />
        </template>
        <v-card-text class="pa-8">
            <ProjectMoveForm
                :portfolio-id
                :focus-id
                :need-id
                @submit="onSubmit"
                @cancel="showDialog = false"
            />
        </v-card-text>
    </AppModal>
</template>
