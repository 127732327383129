<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { VCardText } from 'vuetify/components';
import AppModal from '@/components/AppModal.vue';
import BottleneckTable, { type BottleneckItem } from './BottleneckTable.vue';
import { computed, watchEffect, watch } from 'vue';
import { graphql } from '@/gql';
import { useQuery } from '@vue/apollo-composable';
import { BottleneckStatus } from '@/gql/graphql';

const {
    items = undefined,
    loading = false,
    canUpdate = false,
    canDelete = false,
    deliverableId = undefined,
} = defineProps<{
    items?: BottleneckItem[];
    deliverableId?: string;
    canUpdate: boolean;
    canDelete: boolean;
    loading: boolean;
}>();

const { t } = useI18n({
    messages: {
        nl: {
            title: 'Status wijzigen',
            text: 'Er staan nog bottlenecks of escalaties open. Pas deze eerst aan voordat de status aangepast kan worden',
        },
        en: {
            title: 'Change status',
            text: 'There are still bottlenecks or escalations open. First adjust these before the status can be changed',
        },
    },
});

const variables = computed(() => ({
    deliverableId: deliverableId,
}));

const { result: bottlenecksResult, loading: isLoadingBottlenecks } = useQuery(
    graphql(`
        query DeliverableBottlenecks($deliverableId: ID!) {
            deliverable(deliverableId: $deliverableId) {
                state
                bottlenecks {
                    id
                    name
                    status
                    solution
                    description
                    resolutionDate
                    responsible
                }
            }
        }
    `),
    variables
);

const emit = defineEmits<{
    close: [];
    allSolved: [];
}>();

const templateItems = computed<BottleneckItem[]>(
    () => items || bottlenecksResult.value?.deliverable.bottlenecks || []
);

const nonFinishedCount = computed(
    () =>
        templateItems.value.filter(
            (item) => item.status !== BottleneckStatus.SOLVED
        ).length
);

watch(nonFinishedCount, (newVal, oldVal) => {
    if (oldVal === 1 && newVal === 0) {
        emit('allSolved');
        emit('close');
    }
});

watchEffect(() => {
    if (items?.length === 0) {
        emit('close');
    }
});
</script>

<template>
    <AppModal :title="t('title')" height="auto" :width="552">
        <VCardText class="pa-8">
            <p class="text-body-small">{{ t('text') }}</p>
            <BottleneckTable
                :items="templateItems"
                :columns="['name', 'status']"
                :loading="loading || isLoadingBottlenecks"
                :can-update
                :can-delete
            />
        </VCardText>
    </AppModal>
</template>
