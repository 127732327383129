<script lang="ts" setup>
import { VCard, VCardText, VRow, VCol } from 'vuetify/components';
import AppCardTitleWithIcon from './AppCardTitleWithIcon.vue';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

const { t } = useI18n({
    messages: {
        nl: {
            description: 'Probleemomschrijving',
            scope: 'Scope',
            result: 'Resultaat',
            risks: "Risico's en maatregelen",
            stakeholders: 'Stakeholders',
            no_data: 'Geen gegevens ingevuld',
        },
        en: {
            description: 'Problem description',
            scope: 'Scope',
            result: 'Result',
            risks: 'Risks and measures',
            stakeholders: 'Stakeholders',
            no_data: 'No data filled in',
        },
    },
});

const props = defineProps<{
    description?: string;
    goal?: string;
    scope?: string;
    result?: string;
    risks?: string;
    stakeholders?: string;
}>();

const data = computed(() => {
    return [
        {
            title: t('description'),
            icon: 'display_settings',
            text: props.description,
        },
        {
            title: t('goal'),
            icon: 'display_settings',
            text: props.goal,
        },
        {
            title: t('scope'),
            icon: 'display_settings',
            text: props.scope,
        },
        {
            title: t('result'),
            icon: 'display_settings',
            text: props.result,
        },
        {
            title: t('risks'),
            icon: 'problem',
            text: props.risks,
        },
        {
            title: t('stakeholders'),
            icon: '$team',
            text: props.stakeholders,
        },
    ];
});
</script>

<template>
    <v-card>
        <v-card-text>
            <v-row>
                <v-col v-for="col in data" :key="col.title" md="6" class="pa-0">
                    <v-card>
                        <template #title>
                            <AppCardTitleWithIcon :icon="col.icon">
                                {{ col.title }}
                            </AppCardTitleWithIcon>
                        </template>
                        <v-card-text>
                            <p
                                v-if="col.text"
                                v-html="col.text.replace(/\n/g, '<br>')"
                            ></p>
                            <p v-else class="text-label-medium text-neutral-50">
                                {{ t('no_data') }}
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
