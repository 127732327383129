<script setup lang="ts">
import { useField, useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { VForm, VBtn, VTextarea } from 'vuetify/components';
import { toTypedSchema } from '@vee-validate/zod';
import { requirements } from '@/utils/formRequirements';
import { string, object, literal } from 'zod';
import type { NonNullableFields } from '@/utils';

export interface BottleneckSolveFormValues {
    solution: string;
}

const props = defineProps<{
    initialValues?: Omit<BottleneckSolveFormValues, 'solution'> & {
        solution: string | null;
    };
    disabled?: boolean;
    loading?: boolean;
}>();

const emit = defineEmits<{
    submit: [values: NonNullableFields<BottleneckSolveFormValues>];
    cancel: [];
}>();

const { t } = useI18n({
    messages: {
        nl: {
            solution: 'Omschrijf hoe de bottleneck is opgelost',
        },
        en: {
            solution: 'Describe how the bottleneck has been solved',
        },
    },
});

const { bottleneck } = requirements;

const validationSchema = object({
    solution: string()
        .min(bottleneck.solution.min, t('error.required_field'))
        .max(
            bottleneck.solution.max,
            t('error.maximum_characters', { max: bottleneck.solution.max })
        )
        .or(literal(null).refine(() => false, t('error.required_field'))),
});

const { handleSubmit, meta } = useForm({
    validationSchema: toTypedSchema(validationSchema),
    initialValues: props.initialValues
        ? props.initialValues
        : {
              solution: null,
          },
});

const solution = useField<string>('solution');

const submit = handleSubmit((values) => {
    emit('submit', values as NonNullableFields<BottleneckSolveFormValues>);
});
</script>

<template>
    <v-form class="d-flex flex-column ga-3" :disabled @submit.prevent="submit">
        <v-textarea
            v-model="solution.value.value"
            :label="t('solution')"
            :error-messages="solution.errorMessage.value"
            rows="9"
            autofocus
        />
        <div class="d-flex justify-end ga-3 mt-5">
            <v-btn variant="text" @click="$emit('cancel')">
                {{ t('cancel') }}
            </v-btn>
            <v-btn
                type="submit"
                variant="elevated"
                :disabled="!meta.valid"
                :loading
            >
                {{ t('save') }}
            </v-btn>
        </div>
    </v-form>
</template>
