<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import { useMutation } from '@vue/apollo-composable';
import { useNotificationStore } from '@/stores/notification';
import { useField } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/zod';
import { graphql } from '@/gql';
import { string } from 'zod';
import { watchEffect } from 'vue';

const props = defineProps<{ phaseId: string }>();
const emit = defineEmits<{ success: []; loading: [value: boolean] }>();

const { t } = useI18n({
    messages: {
        nl: {
            delete: 'Verwijderen',
            delete_title: 'Permanent verwijderen',
            delete_confirmation:
                'Weet je zeker dat je deze fase wilt verwijderen?',
            delete_success: 'Fase succesvol verwijderd',
        },
        en: {
            delete: 'Delete',
            delete_title: 'Permanently delete',
            delete_confirmation: 'Are you sure you want to delete this phase?',
            delete_success: 'Phase successfully deleted',
        },
    },
});

const notificationStore = useNotificationStore();

const reason = useField(
    'reason',
    toTypedSchema(string().min(1, t('error.required_field')))
);

function handleUpdate(dialogOpen: boolean) {
    if (dialogOpen) {
        reason.resetField();
    }
}

const { mutate: deletePhase, loading: deletePhaseLoading } = useMutation(
    graphql(`
        mutation DeletePhase($input: DeletePhaseInput!) {
            deletePhase(input: $input) {
                ok
            }
        }
    `),
    {
        refetchQueries: ['ProjectPhases'],
        awaitRefetchQueries: true,
    }
);

watchEffect(() => {
    emit('loading', deletePhaseLoading.value);
});

async function handleDelete() {
    try {
        await deletePhase({
            input: {
                phaseId: props.phaseId,
            },
        });

        emit('success');
        notificationStore.add({
            type: 'success',
            message: t('delete_success'),
        });
    } catch {
        notificationStore.add({ type: 'error', message: t('delete_error') });
    }
}
</script>

<template>
    <ConfirmationDialog
        :title="t('delete_title')"
        :confirm-button-text="t('delete')"
        persistent
        :confirm-required="true"
        destructive
        @confirm="handleDelete"
        @update:model-value="handleUpdate"
    >
        <template #activator="{ props: activatorProps }">
            <slot
                name="activator"
                :props="activatorProps"
                :loading="deletePhaseLoading"
            />
        </template>
    </ConfirmationDialog>
</template>
