<script lang="ts" setup>
import { usePermissions } from '@/composables/permissions';
import { PolicyAction } from '@/types';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import type { Project } from '@/gql/graphql';
import {
    VBtn,
    VMenu,
    VList,
    VListItem,
    VListItemTitle,
    VDefaultsProvider,
} from 'vuetify/components';

const { hasPermission } = usePermissions();

const { t } = useI18n({
    messages: {
        nl: {
            pending: 'Te voltooien',
            finished: 'Afgerond',
            bottleneck: 'Bottleneck',
            escalation: 'Escalatie',
        },
        en: {
            pending: 'Pending',
            finished: 'Finished',
            bottleneck: 'Bottleneck',
            escalation: 'Escalation',
        },
    },
});

const {
    label,
    size = 'x-small',
    project,
    loading = false,
    currentStatus,
} = defineProps<{
    label?: string;
    size?: string;
    loading?: boolean;
    project: Pick<Project, 'id' | 'masterPortfolioId'>;
    currentStatus?: 'pending' | 'finished' | 'bottleneck' | 'escalation';
}>();

const items = ref<
    {
        title: string;
        value: 'pending' | 'finished' | 'bottleneck' | 'escalation';
        disabled: boolean;
    }[]
>([
    {
        title: t('pending'),
        value: 'pending',
        disabled: !hasPermission(
            PolicyAction.PROJECT_WRITE,
            project.id,
            project.masterPortfolioId || ''
        ),
    },
    {
        title: t('finished'),
        value: 'finished',
        disabled: !hasPermission(
            PolicyAction.PROJECT_WRITE,
            project.id,
            project.masterPortfolioId || ''
        ),
    },
    {
        title: t('bottleneck'),
        value: 'bottleneck',

        disabled: !hasPermission(
            PolicyAction.PROJECT_WRITE,
            project.id,
            project.masterPortfolioId || ''
        ),
    },
    // TODO: Escalation write permissions cannot be checked in DeliverableTableRow.vue
    {
        title: t('escalation'),
        value: 'escalation',
        disabled:
            /*!hasPermission(PolicyAction.BOTTLENECK_WRITE, props.item.id)*/ false,
    },
]);

export type DeliverableStatus =
    | 'pending'
    | 'finished'
    | 'bottleneck'
    | 'escalation';

const emit = defineEmits<{
    select: [status: DeliverableStatus];
}>();

function handleSelect(value: DeliverableStatus) {
    emit('select', value);
}
</script>
<template>
    <v-menu>
        <template #activator="{ props }">
            <VDefaultsProvider
                :defaults="{
                    VBtn: {
                        style: {
                            textTransform: 'none',
                            fontFamily: 'inherit',
                        },
                        class: ['px-0', 'text-none', 'text-label-large'],
                    },
                }"
            >
                <VBtn
                    append-icon="$expand"
                    v-bind="props"
                    color="primary-0"
                    variant="plain"
                    :size
                    :loading
                >
                    <template v-if="label">{{ t(label) }}</template>
                </VBtn>
            </VDefaultsProvider>
        </template>
        <v-list>
            <v-list-item
                v-for="item in items"
                :key="item.title"
                :value="item.value"
                :disabled="item.disabled || item.value === currentStatus"
            >
                <v-list-item-title @click="handleSelect(item.value)">{{
                    item.title
                }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
