<script setup lang="ts">
import { VCard, VCardText, VDefaultsProvider } from 'vuetify/components';
import AppCardTitleWithIcon from './AppCardTitleWithIcon.vue';

defineProps<{
    icon: string;
    title: string;
    loading: boolean;
}>();

const defaults = {
    VCard: {
        class: ['pa-2', 'pt-4'],
        VCardTitle: {
            class: [],
        },
        VCardText: {
            class: ['pb-2'],
        },
    },
    VList: {
        bgColor: 'transparent',
        VListItem: {
            class: ['px-0'],
            VListItemTitle: {
                class: ['text-label-medium', 'text-on-surface', 'mb-1'],
            },
            VListItemSubtitle: {
                class: ['text-body-small', 'text-on-surface'],
            },
        },
    },
};
</script>

<template>
    <v-defaults-provider :defaults>
        <v-card :loading>
            <template #title>
                <AppCardTitleWithIcon :icon icon-color="primary-20">
                    {{ title }}
                </AppCardTitleWithIcon>
            </template>

            <v-card-text>
                <template v-if="!loading">
                    <slot />
                </template>
            </v-card-text>
        </v-card>
    </v-defaults-provider>
</template>
