<script lang="ts" setup>
import { useRouteParams } from '@vueuse/router';
import { VContainer, VRow, VCol } from 'vuetify/components';
import ProjectHighlights from './ProjectHighlights.vue';
import ProjectPreviews from './ProjectPreviews.vue';

const id = useRouteParams('id');
</script>

<template>
    <v-container class="pt-6">
        <v-row>
            <v-col md="6">
                <ProjectHighlights :project-id="String(id)" />
            </v-col>
            <v-col md="6">
                <ProjectPreviews :project-id="String(id)" />
            </v-col>
        </v-row>
    </v-container>
</template>
