<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import BottleneckModal from './BottleneckModal.vue';
import BottleneckForm, {
    type BottleneckFormValues,
} from './BottleneckForm.vue';
import { useMutation } from '@vue/apollo-composable';
import { graphql } from '@/gql';
import { useNotificationStore } from '@/stores/notification';
import { computed } from 'vue';
import type { NonNullableFields } from '@/utils';

const showDialog = defineModel<boolean>({ default: false });

const props = defineProps<{
    bottleneck: {
        id: string;
        name: string;
        description: string;
        responsible: string;
        solution: string | null;
        resolutionDate: string | null;
    };
}>();

const { t } = useI18n({
    messages: {
        nl: {
            title: 'Bottleneck bewerken',
            update_success: 'Bottleneck succesvol bewerkt',
        },
        en: {
            title: 'Edit bottleneck',
            update_success: 'Bottleneck successfully edited',
        },
    },
});

const notificationStore = useNotificationStore();

const { mutate: updateBottleneck, loading: isUpdating } = useMutation(
    graphql(`
        mutation UpdateBottleneck($input: UpdateBottleneckInput!) {
            updateBottleneck(input: $input) {
                result
            }
        }
    `),
    {
        refetchQueries: ['Deliverable', 'DeliverableBottlenecks'],
        awaitRefetchQueries: true,
    }
);

async function handleUpdate({
    resolutionDate,
    ...values
}: NonNullableFields<BottleneckFormValues>) {
    try {
        await updateBottleneck({
            input: {
                bottleneckId: props.bottleneck.id,
                resolutionDate: resolutionDate.toISOString(),
                ...values,
            },
        });

        showDialog.value = false;
        notificationStore.add({
            type: 'success',
            message: t('update_success'),
        });
    } catch {
        notificationStore.add({ type: 'error', message: t('update_error') });
    }
}

const initialValues = computed(() => ({
    ...props.bottleneck,
    resolutionDate: props.bottleneck.resolutionDate
        ? new Date(props.bottleneck.resolutionDate)
        : null,
}));
</script>

<template>
    <BottleneckModal v-model="showDialog" :title="t('title')">
        <template #activator="{ props: activatorProps }">
            <slot name="activator" :props="activatorProps" />
        </template>
        <BottleneckForm
            :disabled="isUpdating"
            :loading="isUpdating"
            :initial-values
            @cancel="showDialog = false"
            @submit="handleUpdate"
        />
    </BottleneckModal>
</template>
