<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import HighlightModal from './HighlightModal.vue';
import HighlightForm, { type HighlightFormValues } from './HighlightForm.vue';
import { useMutation } from '@vue/apollo-composable';
import { graphql } from '@/gql';
import { useNotificationStore } from '@/stores/notification';

const showDialog = defineModel<boolean>({ default: false });
const { highlight } = defineProps<{
    highlight: HighlightFormValues & { id: string };
}>();

const { t } = useI18n({
    messages: {
        nl: {
            title: '@:highlight bewerken',
            update_success: '@:highlight succesvol bewerkt',
        },
        en: {
            title: 'Edit @.lower:highlight',
            update_success: '@:highlight successfully edited',
        },
    },
});

const notificationStore = useNotificationStore();

const { mutate: updateHighlight, loading: isUpdating } = useMutation(
    graphql(`
        mutation UpdateHighlight($input: UpdateHighlightInput!) {
            updateHighlight(input: $input) {
                ok
            }
        }
    `),
    {
        refetchQueries: ['ProjectHighlights'],
        awaitRefetchQueries: true,
    }
);

async function handleUpdate(values: HighlightFormValues) {
    try {
        await updateHighlight({
            input: { highlightId: highlight.id, ...values },
        });

        showDialog.value = false;
        notificationStore.add({
            type: 'success',
            message: t('update_success'),
        });
    } catch {
        notificationStore.add({ type: 'error', message: t('update_error') });
    }
}
</script>

<template>
    <HighlightModal v-model="showDialog" :title="t('title')">
        <template #activator="{ props: activatorProps }">
            <slot name="activator" :props="activatorProps" />
        </template>
        <HighlightForm
            :disabled="isUpdating"
            :loading="isUpdating"
            :initial-values="highlight"
            @cancel="showDialog = false"
            @submit="handleUpdate"
        />
    </HighlightModal>
</template>
