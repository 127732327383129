<script setup lang="ts">
import { computed, type CSSProperties } from 'vue';

const props = withDefaults(
    defineProps<{
        isEditing: boolean;
    }>(),
    {
        isEditing: false,
    }
);

const style = computed<CSSProperties>(() =>
    props.isEditing
        ? {
              zIndex: '1000',
              position: 'fixed',
              width: 'calc(100% - 360px)',
          }
        : {}
);
</script>

<template>
    <div class="app-view-header bg-primary-20" :style>
        <slot />
    </div>
</template>
