<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import { useMutation } from '@vue/apollo-composable';
import { useNotificationStore } from '@/stores/notification';
import { graphql } from '@/gql';

const props = defineProps<{ projectId: string }>();

const { t } = useI18n({
    inheritLocale: true,
    messages: {
        nl: {
            convert: 'Omzetten',
            convert_title: 'Omzetten naar @.lower:project',
            convert_confirmation:
                "Weet je zeker dat je dit @.lower:subproject wilt omzetten naar een @.lower:{'project'}?",
            convert_success: '@:subproject succesvol omgezet',
            convert_error: 'Er is een fout opgetreden',
        },
        en: {
            convert: 'convert',
            convert_title: 'Convert @.lower:subproject to @.lower:project',
            convert_confirmation:
                "Are you sure you want to convert this @.lower:subproject to a @.lower:{'project'}?",
            convert_success: '@:subproject successfully converted',
            convert_error: 'An error occurred',
        },
    },
});

const notificationStore = useNotificationStore();

const { mutate: convertSubproject } = useMutation(
    graphql(`
        mutation ConvertSubproject($input: ConvertSubprojectInput!) {
            convertSubproject(input: $input) {
                ok
            }
        }
    `),
    {
        refetchQueries: ['ProjectDetails', 'Project'],
        awaitRefetchQueries: true,
    }
);

async function handleConfirm() {
    try {
        await convertSubproject({
            input: {
                projectId: props.projectId,
            },
        });

        notificationStore.add({
            type: 'success',
            message: t('convert_success'),
        });
    } catch {
        notificationStore.add({
            type: 'error',
            message: t('convert_error'),
        });
    }
}
</script>

<template>
    <ConfirmationDialog
        :title="t('convert_title')"
        :text="t('convert_confirmation')"
        :confirm-button-text="t('convert')"
        @confirm="handleConfirm"
    >
        <template #activator="{ props: activatorProps }">
            <slot name="activator" :props="activatorProps" />
        </template>
    </ConfirmationDialog>
</template>
