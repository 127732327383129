<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import { useMutation } from '@vue/apollo-composable';
import { useNotificationStore } from '@/stores/notification';
import { graphql } from '@/gql';
import { watchEffect } from 'vue';

const { highlightId } = defineProps<{ highlightId: string }>();
const emit = defineEmits<{ success: []; loading: [value: boolean] }>();

const { t } = useI18n({
    messages: {
        nl: {
            delete: 'Verwijderen',
            delete_title: 'Permanent verwijderen',
            delete_confirmation:
                'Weet je zeker dat je deze @.lower:highlight wilt verwijderen?',
            delete_success: '@:highlight succesvol verwijderd',
        },
        en: {
            delete: 'Delete',
            delete_title: 'Permanently delete',
            delete_confirmation:
                'Are you sure you want to delete this @.lower:highlight?',
            delete_success: '@:highlight successfully deleted',
        },
    },
});

const notificationStore = useNotificationStore();

const { mutate: deleteHighlight, loading: deleteHighlightLoading } =
    useMutation(
        graphql(`
            mutation DeleteHighlight($input: DeleteHighlightInput!) {
                deleteHighlight(input: $input) {
                    ok
                }
            }
        `),
        {
            refetchQueries: ['ProjectHighlights'],
            awaitRefetchQueries: true,
        }
    );

watchEffect(() => {
    emit('loading', deleteHighlightLoading.value);
});

async function handleDelete() {
    try {
        await deleteHighlight({ input: { highlightId } });

        emit('success');
        notificationStore.add({
            type: 'success',
            message: t('delete_success'),
        });
    } catch {
        notificationStore.add({ type: 'error', message: t('delete_error') });
    }
}
</script>

<template>
    <ConfirmationDialog
        :title="t('delete_title')"
        :text="t('delete_confirmation')"
        :confirm-button-text="t('delete')"
        persistent
        @confirm="handleDelete"
    >
        <template #activator="{ props: activatorProps }">
            <slot
                name="activator"
                :props="activatorProps"
                :loading="deleteHighlightLoading"
            />
        </template>
    </ConfirmationDialog>
</template>
